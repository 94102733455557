import { useEffect, useState } from "react";
import { useAppSelector } from "store/hooks";
import { companyMetadataSelector } from "store/selectors";

import { SalsaOnboardingStatus } from "types/BETypes";
import { showErrorModal } from "helpers";
import { getDeferredData } from "helpers/employee/employeeRequests";
import useAuth from "hooks/useAuth";

import {
  CompanyResponseDto,
  mutationPlaidControllerAcceptDisclosure,
} from "utils/swagger_react_query";

import { ADMIN_SETUP_STEPS } from "./constants";

export const useAdminSetup = () => {
  const currentCompany = useAppSelector(companyMetadataSelector);

  const [isLoading, setLoading] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<number | undefined>(undefined);
  const [showWelcomeMessage, setShowWelcomeMessage] = useState<boolean>(false);
  const { getCompanyInfo, saveCompanyToStore } = useAuth();
  const [isContinueToOnboardingDisabled, setContinueToOnboardingDisabled] = useState<
    Record<ADMIN_SETUP_STEPS, boolean>
  >({
    [ADMIN_SETUP_STEPS.SETUP_PAYROLL]: true,
    [ADMIN_SETUP_STEPS.CONNECT_BANK]: true,
  });

  useEffect(() => {
    if (currentCompany?.salsaOnboardingStatus === SalsaOnboardingStatus.COMPLETED) {
      setContinueToOnboardingDisabled({
        ...isContinueToOnboardingDisabled,
        [ADMIN_SETUP_STEPS.SETUP_PAYROLL]: false,
      });
      setCurrentStep(ADMIN_SETUP_STEPS.CONNECT_BANK);
    } else {
      setShowWelcomeMessage(true);
      setCurrentStep(ADMIN_SETUP_STEPS.SETUP_PAYROLL);
    }
  }, []);

  useEffect(() => {
    if (connectBankUnblockCondition(currentCompany)) {
      handleOnboardingDisabledChange(false, ADMIN_SETUP_STEPS.SETUP_PAYROLL);
    }
  }, [currentCompany?.salsaOnboardingStatus, currentStep]);

  const refetchCompanyData = async () => {
    const result = await getCompanyInfo(currentCompany?.companyId || "", true);
    return result.companyInfo;
  };

  const handleOnboardingDisabledChange = (isDisabled: boolean, stepIndex: ADMIN_SETUP_STEPS) => {
    setContinueToOnboardingDisabled((prev) => ({ ...prev, [stepIndex]: isDisabled }));
  };

  const connectBankUnblockCondition = (company?: CompanyResponseDto | null) => {
    const result = company?.salsaOnboardingStatus === SalsaOnboardingStatus.COMPLETED;
    return result;
  };

  const handleBankAgreementSubmit = async () => {
    try {
      setLoading(true);
      await mutationPlaidControllerAcceptDisclosure()();
      await refetchCompanyData();
    } catch (error) {
      showErrorModal(error);
    } finally {
      setLoading(false);
    }
  };

  const moveToOnboardingStep = () => {
    setCurrentStep(ADMIN_SETUP_STEPS.SETUP_PAYROLL);
  };

  const moveToBankAccountsStep = () => {
    if (!isContinueToOnboardingDisabled[ADMIN_SETUP_STEPS.SETUP_PAYROLL]) {
      setCurrentStep(ADMIN_SETUP_STEPS.CONNECT_BANK);
    }
  };

  const handleContinueToBankAccount = () => {
    onSalsaOnboardingComplete();
  };

  const onSalsaOnboardingComplete = async () => {
    setLoading(true);
    //Our company might not have updated status right away, we need to wait for it to become completed
    const result = await getDeferredData(refetchCompanyData, connectBankUnblockCondition, 2000);
    if (connectBankUnblockCondition(result)) {
      saveCompanyToStore(result);
      setCurrentStep(ADMIN_SETUP_STEPS.CONNECT_BANK);
    } else {
      showErrorModal();
    }

    setLoading(false);
  };

  const handleWelcomeComponentContinueClick = () => {
    setShowWelcomeMessage(false);
  };

  return {
    isLoading,
    currentStep,
    actions: {
      moveToOnboardingStep,
      moveToBankAccountsStep,
      handleContinueToBankAccount,
      handleWelcomeComponentContinueClick,
      handleBankAgreementSubmit,
    },
    pageData: {
      isContinueToOnboardingDisabled,
      showWelcomeMessage,
    },
  };
};
