import { FC, useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { QuestionmarkCircleIcon } from "assets/svg";
import { Formik, FormikContextType, useFormikContext } from "formik";

import { getSeparatedNumber } from "helpers";
import CurrencySumComponent from "components/CurrencySumComponent";
import MFAVerifyCodeInput from "components/MultiFactorAuthorization/components/sms/steps/VerifyCode/components/DefaultInput/DefaultInput";
import {
  FormType as VerifyMFACodeFormType,
  validationSchema as VerifyMFACodeValidationSchema,
} from "components/MultiFactorAuthorization/components/sms/steps/VerifyCode/validationSchema";

import { ModalBottomButtons } from "uikit";
import { EButtonsFlex, IModalBottomButtons, IModalProps } from "uikit/Modal";

import { CILabel, CIValue, ContentItem, MFAVerifyCodeContainer, StyledUIModal } from "./styles";

interface IProps {
  originTranslationPrefix?: string;
  fromAccountNumber: string;
  toAccountNumber: string;
  amount: string;
  onClose: () => void;
  onSubmit: (formikContext: FormikContextType<VerifyMFACodeFormType>) => void;
  onVerifyCodeResend: (resetTimerCallback: () => void) => void;
  isOpen: boolean;
}

interface IContentProps extends IProps {
  modalConfig: IModalProps;
  onSubmitAttempt: () => void;
}

const TransferConfirmationModalContent: FC<IContentProps> = (props) => {
  const {
    originTranslationPrefix,
    fromAccountNumber,
    toAccountNumber,
    amount,
    modalConfig,
    onSubmitAttempt,
    onSubmit,
    onVerifyCodeResend,
    onClose,
  } = props;
  const translationPrefix = `${originTranslationPrefix}.modals`;
  const formikContext = useFormikContext<VerifyMFACodeFormType>();
  const buttonsConfig: IModalBottomButtons = {
    ...modalConfig,
    mainButton: {
      text: <Trans i18nKey={`buttons.confirm`} />,
      onClick: () => {
        onSubmitAttempt();
        onSubmit(formikContext);
      },
    },
    secondaryButton: {
      text: <Trans i18nKey={`buttons.cancel`} />,
      onClick: onClose,
    },
    buttonsFlex: EButtonsFlex.ROW_REVERSE,
  };

  return (
    <>
      <ContentItem>
        <CILabel>
          <Trans i18nKey={`${translationPrefix}.labels.from`} />
        </CILabel>
        <CIValue>{getSeparatedNumber(fromAccountNumber)}</CIValue>
      </ContentItem>

      <ContentItem>
        <CILabel>
          <Trans i18nKey={`${translationPrefix}.labels.to`} />
        </CILabel>
        <CIValue>**** **** **** {toAccountNumber}</CIValue>
      </ContentItem>

      <ContentItem>
        <CILabel>
          <Trans i18nKey={`${translationPrefix}.labels.amount`} />
        </CILabel>
        <CIValue>
          <CurrencySumComponent sum={Number(amount)} />
        </CIValue>
      </ContentItem>

      <MFAVerifyCodeContainer>
        <MFAVerifyCodeInput onResend={onVerifyCodeResend} />
      </MFAVerifyCodeContainer>

      <ModalBottomButtons {...buttonsConfig} />
    </>
  );
};

export const TransferConfirmationModal: FC<IProps> = (props) => {
  const { originTranslationPrefix, onClose, isOpen } = props;
  const translationPrefix = `${originTranslationPrefix}.modals`;

  const [svoc, setSvoc] = useState(false);

  const handleSubmitAttempt = () => {
    setSvoc(true);
  };

  useEffect(() => {
    if (!isOpen) {
      setSvoc(false);
    }
  }, [props]);

  const modalConfig: IModalProps = {
    isOpen,
    onClose,
    title: <Trans i18nKey={`${translationPrefix}.transfer_confirmation.title`} />,
    message: <Trans i18nKey={`${translationPrefix}.transfer_confirmation.message`} />,
    icon: <QuestionmarkCircleIcon />,
    onCloseOnButtons: false,
  };

  return (
    <StyledUIModal {...modalConfig}>
      <Formik<VerifyMFACodeFormType>
        initialValues={{
          code: "",
        }}
        validationSchema={VerifyMFACodeValidationSchema}
        onSubmit={() => {}}
        validateOnChange={svoc}
      >
        <TransferConfirmationModalContent
          {...props}
          modalConfig={modalConfig}
          onSubmitAttempt={handleSubmitAttempt}
        />
      </Formik>
    </StyledUIModal>
  );
};
