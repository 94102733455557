export interface IProps {
  onInviteWorker?: () => void;
  onDeleteWorker?: () => void;
  onAddWorker?: () => void;
  onEditWorker?: () => void;
}

export interface IResendDisableRecord {
  id: string;
  datetime: number;
}

export enum WorkerContextMenuActionType {
  FINISH_SETUP = "FINISH_SETUP",
  INVITE_WORKER = "INVITE_WORKER",
  RESEND_INVITE = "RESEND_INVITE",
  ON_EDIT_WORKER = "ON_EDIT_WORKER",
  ON_DELETE_WORKER = "ON_DELETE_WORKER",
}
