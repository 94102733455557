import { FC, ReactNode } from "react";
import { Provider as ReduxProvider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { store } from "store/store";
import { ThemeProvider } from "styled-components";

import { GlobalModal } from "components/GlobalModal";
import ResponsiveAlert from "components/ResponsiveAlert";
import { ValidationModal } from "components/ValidationModal";

import useRouterConfig from "./routes/router";
import GlobalStyles from "./styles/styled";
import { theme } from "./styles/theme";
import ImpersonateAlert from "./components/ImpersonateAlert";

interface IReduxProviderContainer {
  children?: ReactNode;
  store: any;
}

const ReduxProviderChildrenWrapper: FC = () => {
  const { getCreatedRouter } = useRouterConfig();
  const Router = getCreatedRouter();

  return (
    <ThemeProvider theme={theme}>
      <ImpersonateAlert />
      <ResponsiveAlert />
      <GlobalStyles />
      <RouterProvider router={Router} />
      <GlobalModal />
      <ValidationModal />
    </ThemeProvider>
  );
};

function App() {
  return (
    <ReduxProvider store={store}>
      <ReduxProviderChildrenWrapper />
    </ReduxProvider>
  );
}

export default App;
