import { EBusinessStructureUserStatus } from "types/BETypes";
import { APPLICATION_NAME } from "constants/systemConstants";
import { EKybResponseType } from "components/BusinessOwnersStructure/types";
import { EKycResponseType } from "components/CompanyBankAccountComponents/employee/KYCVerification/types";

const companyBankAccountComponents = {
  "en-US": {
    card_shipping_step: {
      page_title: "Bank Accounts",

      title: `Issue a Card`,
      description: {
        employee: `Where would you like your new ${APPLICATION_NAME} Debit Card to be shipped?`,
        admin: `Provide the required information to receive your tailored physical or virtual card.`,
      },
      shipping_details: {
        title: "Shipping Details",
        cardholder_agreement:
          "I have read and understood the terms of the <1>cardholder agreement</1>",
        admin_form_header: {
          user_select: {
            title: "Card Owner",
            description: "Select card owner.",
          },
          card_type: {
            title: "Shipping Details",
            description: `Where would you like ${APPLICATION_NAME} Business Card to be shipped?`,
          },
        },
        cardholder_errors: {
          [EBusinessStructureUserStatus.INVITED]: {
            title: "Only active users can become a cardholder",
            message: "This user must log in and activate their account to become a cardholder.",
          },
          [EBusinessStructureUserStatus.NOT_INVITED]: {
            title: "This user is not invited to the system",
            message: "Only active users can become a cardholder.",
            sent_invitation_btn: "Send an Invite",
            invitation_sent: "Invite Sent",
          },
        },
      },
      card_template_section: {
        title: `${APPLICATION_NAME} Debit Card`,
        description: "Use at ATMs and anywhere MasterCard is accepted.",
      },
      card_template_section_admin: {
        title: `${APPLICATION_NAME} Business Card`,
        description: "Use at ATMs and anywhere MasterCard is accepted.",
      },
    },
    mfa_widget: {
      title: "Enable Two-Factor Authentication",
      description: "Enhance your account security by adding an extra layer of protection.",
    },
    kyc_verification_widget: {
      title: `Create ${APPLICATION_NAME} Bank Account`,
      protection_description: "Provide you personal information and create Paid Bank Account",
    },
    early_pay_form: {
      title: "Enroll in Early Pay",
      description: "Get 25% of your net pay 2 days early at ZERO cost.",
      switch: {
        title: `Enroll in Early Pay`,
        description: `Yes, I would like to enroll in Early Pay and get 25% of my Net Pay 2 days before payday at ZERO cost.`,
      },
    },
    bank_account_setup_completed_section: {
      title: `${APPLICATION_NAME} Bank Account Set Up Finished!`,
      message: `Great job! You got everything to use your ${APPLICATION_NAME} Bank Account!`,
    },
    physical_card_order_success_modal: {
      title: "Physical Card has been successfully ordered",
      message: `Great job! You got everything to use your ${APPLICATION_NAME} Bank Account!`,
    },
    finish_setup_banner: {
      title: `Finish setting up your ${APPLICATION_NAME} Bank Account`,
      message: {
        employee: "Continue with ordering your card to unlock all features.",
        admin: "Continue with ordering your card and enabling 2FA to unlock all features.",
      },
      continue_setup_btn: "Continue Setup",
    },
    admin: {
      business_information: {
        title: "Provide Your Company Information",
        protection_description:
          "Enter essential details about your company. This helps verify your business for secure transactions.",
        terms_and_conditions: `I agree to the <1>terms and conditions</1> for setting up a ${APPLICATION_NAME} Bank Account`,
        form: {
          inputs: {
            company_name: {
              label: "Company Name",
              errors: {},
            },
            operating_name: {
              label: "Operating Name",
              errors: {},
            },
            business_formation_date: {
              label: "Date of Business Formation",
              errors: {},
            },
            state_of_incorporation: {
              label: "State of Incorporation",
              errors: {},
            },
            business_structure: {
              label: "Business Structure",
              placeholder: "Enter Type",
              errors: {},
            },
            business_phone: {
              label: "Business Phone Number",
              errors: {},
            },
            business_email: {
              label: "Business Email",
              errors: {},
            },
            ein: {
              label: "Employer Identification Number (EIN)",
              label_short: "EIN",
              placeholder: "Enter Identification Number",
              errors: {},
            },
            address: {
              label: "Address",
              errors: {},
            },
            city: {
              label: "City",
              errors: {},
            },
            suite: {
              label: "Suite",
              errors: {},
            },
            state: {
              label: "State",
              errors: {},
            },
            zip: {
              label: "Zip Code",
              errors: {},
            },
          },
        },
      },
      business_structure: {
        title: "Owners & Key Manager",
        description:
          "Submit personal details to verify your identity as the business owner or authorized representative.",
      },
      review_and_certify: {
        title: "Review and Submit",
        description:
          "Review and accept the terms and conditions to proceed with setting up your Paid Business Account.",
      },
    },
    shared: {
      kyb_kyc_response_section: {
        title: "Provide your personal information",
        terms_and_conditions: `I agree to the <1>terms and conditions</1> for setting up a ${APPLICATION_NAME} Bank Account`,
        restart_verification_btn: "Restart Verification",
        verification_errors_title: "Verification Error:",

        verification_statuses: {
          [EKycResponseType.SUCCESS]: {
            title: `${APPLICATION_NAME} Bank Account Created!`,
            message: `You successfully verified your personal information and created ${APPLICATION_NAME} Bank Account`,
          },
          [EKycResponseType.VERIFICATION_IS_TAKING_TOO_LONG]: {
            title: "Pending Verification",
            message:
              "Verifying the information is taking longer that expected. You will be notified via email when the process is complete. It may take up to a few hours. Sorry for the wait.",
          },
          [EKycResponseType.ERROR]: {
            title: "Trouble verifying your identity",
            message:
              "Please review your information to ensure it is correct. If you continue to face problems, please contact:<br/><1></1>",
          },
          [`${EKybResponseType.ERROR_BANK_ACCOUNT_CREATED}`]: {
            title: "Trouble verifying your identity",
            message: "Please contact:<br/><1></1>",
          },
          [EKycResponseType.INCORRECT_INFO]: {
            title: "Please update your information.",
            message: `
                      You've already submitted this information. Please update your information to avoid errors.
                      <br/>
                      If you continue to have problems, please contact:
                      <br/>
                      <1></1>
                    `,
          },
        },
        modals: {
          [EKycResponseType.VERIFICATION_IS_TAKING_TOO_LONG]: {
            title: "What’s taking so long?",
            message: `Verifying the information is taking longer that expected. You will be notified via email when the process is complete. It may take up to a few hours. Sorry for the wait.`,
          },
        },
      },
      promo_section: {
        employee: {
          title: "Setup Paid Bank Account",
          description: "To enjoy exclusive benefits, follow these",
          sub_title: "{{stepsNumber}} simple steps:",
          steps_descriptions_list: [
            {
              title: `Create ${APPLICATION_NAME} Bank Account`,
              description: `Provide your personal information and create ${APPLICATION_NAME} Bank Account`,
            },
            {
              title: "Enroll in Early Pay",
              description:
                "Decide if you want to get 25% of your net pay 2 days early at ZERO cost",
            },
            {
              title: `Order your physical ${APPLICATION_NAME} card`,
              description: `To start using your ${APPLICATION_NAME} Bank Account you need to request a Physical Card`,
            },
            {
              title: `Enable Two-Factor Authentication`,
              description: `To proceed with transferring funds enable Two-Factor Authentication (2FA)`,
            },
          ],
        },
        admin: {
          title: "Set Up Paid Business Account",
          description: "To enjoy exclusive benefits, follow these",
          sub_title: "{{stepsNumber}} simple steps:",
          steps_descriptions_list: [
            {
              title: "Business Information",
              description:
                "Enter essential details about your company. This helps verify your business for secure transactions.",
            },
            {
              title: "Owners & Key Manager",
              description:
                "Provide information about individuals who own 25% or more of the business and the person with significant management responsibility (e.g., CEO, CFO). This is required for identity verification and compliance checks.",
            },
            {
              title: "Review and Certify",
              description:
                "Review and accept the terms and conditions to proceed with setting up your Paid Business Account.",
            },
            {
              title: `Order Your ${APPLICATION_NAME} Business Card`,
              description: `Once your account is set up, you can order a physical ${APPLICATION_NAME} card, giving you easy access to your business funds and enabling secure purchases and payments.`,
            },
            {
              title: `Enable Two-Factor Authentication`,
              description: `To proceed with transferring funds enable Two-Factor Authentication (2FA)`,
            },
          ],
        },
      },
      steps: {
        steps_current_total_text: "Step {{currentStep}} of {{totalSteps}}",
        skip_button: "Skip for now",
        list: {
          employee: [
            {
              title: `Create ${APPLICATION_NAME} Bank Account`,
            },
            {
              title: "Enroll in Early Pay",
            },
            {
              title: `Order Physical ${APPLICATION_NAME} card`,
            },
            {
              title: `Enable 2FA`,
            },
          ],
          admin: [
            {
              title: `Business Information`,
            },
            {
              title: "Owners & Key Manager",
            },
            {
              title: "Review and Certify",
            },
            {
              title: `Order Your Card`,
            },
            {
              title: `Two-Factor Auth`,
            },
          ],
        },
      },
      info_protected_tooltip:
        "This form uses advanced security measures to protect your personal information",
      profile_form: {
        form_labels: {
          first_name: "First Name",
          last_name: "Last Name",
          phone: "Phone Number",
          social_security_number: "Social Security Number",
          birth_date: "Birth Date",
          address: "Address",
          suite: "Suite",
          city: "City",
          state: "State",
          zip: "Zip Code",
        },
      },
      skip_step_confirmation: {
        title: "Are you sure you want to skip this step?",
        message: "Your physical card won't be shipped",
      },
      close_form_confirmation: {
        title: "Are you sure you want to cancel?",
        common: `If you cancel now, your ${APPLICATION_NAME} Bank Account setup won’t be completed`,
        shipping: "If you cancel now, Physical Card won't be shipped",
        continue_btn: "Continue Setup",
      },
    },
  },
};

export default companyBankAccountComponents;
