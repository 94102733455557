import styled from "styled-components";

import { breakpoint } from "helpers";
import EmployeeInfo from "components/EmployeeInfo";

export const Container = styled.div``;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  ${breakpoint("xs", "xl")`
    flex-direction: column;
    align-items: start;
  `}
`;

export const StyledEmployeeInfo = styled(EmployeeInfo)`
  margin-bottom: 48px;

  ${breakpoint("xs", "xl")`
    margin-bottom: 16px;
  `}
`;
