import { FC } from "react";
import { Trans } from "react-i18next";
import { BuildingIcon, EditIcon, ModalDelete, ModalUser, UserStarIcon } from "assets/svg";

import { BusinessOwnersStructureWidgetType } from "components/BusinessOwnersStructure/types";

import {
  BusinessStructureBusinessOwnersResponseDto,
  BusinessStructureCompanyResponseDto,
  BusinessStructureKeyManagerResponseDto,
  BusinessStructureOwnerResponseDto,
} from "utils/swagger_react_query";

import { EditBtnWithIcon, PercentageText } from "../styles";
import { convertBusinessStructureInfoItemToCardData } from "./helpers";
import { BusinessStructureEntityCardState, BusinessStructureEntityType } from "./types";
import {
  ActionButtonsContainer,
  BusinessKeyManagerBadge,
  Column,
  Container,
  DeleteBtn,
  Description,
  IconContainer,
  Row,
  Title,
} from "./styles";

interface IProps {
  widgetType: BusinessOwnersStructureWidgetType;
  emptyCardEntityType?: BusinessStructureEntityType;
  data?:
    | BusinessStructureKeyManagerResponseDto
    | BusinessStructureOwnerResponseDto
    | BusinessStructureBusinessOwnersResponseDto;
  onEmptyCardClick?: () => void;
  onDelete?: (entityId: string, entityType: BusinessStructureEntityType) => void;
  onEdit?: (entityId: string, entityType: BusinessStructureEntityType) => void;
}

export const BusinessStructureEntityCard: FC<IProps> = ({
  widgetType,
  data,
  emptyCardEntityType,
  onEmptyCardClick,
  onDelete,
  onEdit,
}) => {
  const state = data
    ? BusinessStructureEntityCardState.FILLED
    : BusinessStructureEntityCardState.EMPTY;
  const translationPrefix = `components.business_owner_structure.business_structure_entity_card.${state}`;
  const shouldRenderActionButtonsSection = widgetType === BusinessOwnersStructureWidgetType.SETUP;
  const serializedData = convertBusinessStructureInfoItemToCardData(data);
  const entityType = !data ? emptyCardEntityType : serializedData?.entityType;
  const entityId = () => {
    switch (entityType) {
      case BusinessStructureEntityType.BUSINESS:
        return (data as BusinessStructureCompanyResponseDto)?.businessStructureBusinessId;
      case BusinessStructureEntityType.OWNER:
        return (data as BusinessStructureOwnerResponseDto)?.businessStructurePersonId;
      case BusinessStructureEntityType.KEY_MANAGER:
        return (data as BusinessStructureKeyManagerResponseDto)?.businessStructurePersonId;
      default:
        return "";
    }
  };

  const getIcon = () => {
    switch (entityType) {
      case BusinessStructureEntityType.BUSINESS:
        return <BuildingIcon />;
      case BusinessStructureEntityType.OWNER:
        return <ModalUser className="user" />;
      case BusinessStructureEntityType.KEY_MANAGER:
        return <UserStarIcon />;
      default:
        return <></>;
    }

    return <></>;
  };

  const renderEmptyContent = () => {
    const shouldShowDescription = entityType !== BusinessStructureEntityType.KEY_MANAGER;
    const title = `${translationPrefix}.${entityType}.title`;
    const description = `${translationPrefix}.${entityType}.description`;
    return (
      <>
        <Column>
          <IconContainer>{getIcon()}</IconContainer>
        </Column>
        <Column>
          <Title>
            <Trans i18nKey={title} />
          </Title>
          {shouldShowDescription && (
            <Description>
              <Trans i18nKey={description} />
            </Description>
          )}
        </Column>
      </>
    );
  };

  const renderDescriptionByEntityType = () => {
    if (!entityType) return <></>;

    if (
      [BusinessStructureEntityType.BUSINESS, BusinessStructureEntityType.OWNER].includes(entityType)
    ) {
      return (
        <Description>
          <Trans i18nKey={`${translationPrefix}.ownership_percentage_text_label`} />
          <PercentageText>{serializedData?.ownershipPercentage}%</PercentageText>
        </Description>
      );
    }
    if (entityType === BusinessStructureEntityType.KEY_MANAGER) {
      const keyManagerTitle = (data as BusinessStructureKeyManagerResponseDto).title;
      return (
        <Description>
          <Trans
            i18nKey={`components.business_owner_structure.shared.key_manager_title.${keyManagerTitle}`}
          />
        </Description>
      );
    }
  };

  const renderBusinessOwnerKeyManagerName = () => {
    if (entityType === BusinessStructureEntityType.BUSINESS && serializedData?.relatedKeyManagers) {
      return (
        <BusinessKeyManagerBadge>
          <UserStarIcon />
          {serializedData?.relatedKeyManagers.name}
        </BusinessKeyManagerBadge>
      );
    }
  };

  return (
    <Container
      state={state}
      onClick={state === BusinessStructureEntityCardState.EMPTY ? onEmptyCardClick : undefined}
      data-testid="business-structure-entity-card"
      className={state}
    >
      {state === BusinessStructureEntityCardState.EMPTY ? (
        renderEmptyContent()
      ) : (
        <>
          <Row>
            <IconContainer>{getIcon()}</IconContainer>

            {shouldRenderActionButtonsSection && (onDelete || onEdit) && (
              <ActionButtonsContainer>
                {onEdit && (
                  <EditBtnWithIcon
                    onClick={() => onEdit(entityId(), entityType as BusinessStructureEntityType)}
                    data-testid="business-structure-entity-card-edit-btn"
                  >
                    <EditIcon />
                  </EditBtnWithIcon>
                )}

                {onDelete && (
                  <DeleteBtn
                    onClick={() => onDelete(entityId(), entityType as BusinessStructureEntityType)}
                    data-testid="business-structure-entity-card-delete-btn"
                  >
                    <ModalDelete />
                  </DeleteBtn>
                )}
              </ActionButtonsContainer>
            )}
          </Row>

          <Row>
            <Title>{serializedData?.name}</Title>
            {renderBusinessOwnerKeyManagerName()}
          </Row>
          <Row>{renderDescriptionByEntityType()}</Row>
        </>
      )}
    </Container>
  );
};
