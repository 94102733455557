import { useEffect, useState } from "react";
import { FormikProps } from "formik";
import { isEmpty } from "lodash";
import { useAppSelector } from "store/hooks";
import { companyMetadataSelector, userMetadataSelector } from "store/selectors";

import { getErrorMessageFromResponse } from "helpers/shared/errors";
import useAuth from "hooks/useAuth";

import {
  mutationBusinessBankAccountsControllerCreateBusinessBankAccount,
  mutationBusinessBankAccountsControllerUpdateBusiness,
  queryCompaniesControllerBusinessInfo,
} from "utils/swagger_react_query";

import { BusinessAccountCompanyInfoFormType } from "./components";
import {
  convertAlreadyExistingBusinessInfoToFormData,
  convertBusinessAccountCompanyFormToUpdateBusinessDto,
  convertBusinessFormDataToBusinessAccountData,
  getBusinessInfoFormDataInitValues,
} from "./helpers";
import { IUseBusinessInformationParams } from "./types";

type IHandleSubmitParams = Pick<
  FormikProps<BusinessAccountCompanyInfoFormType>,
  "values" | "validateForm" | "dirty"
>;

export const useBusinessInformation = ({
  onSubmitSuccess,
  businessStructure,
}: IUseBusinessInformationParams) => {
  const { getCompanyInfo } = useAuth();
  const currentUser = useAppSelector(userMetadataSelector);
  const currentCompany = useAppSelector(companyMetadataSelector);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string | null>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [initValues, setInitValues] = useState<BusinessAccountCompanyInfoFormType | undefined>(
    undefined,
  );

  useEffect(() => {
    fetchBusinessInfoInitValues();
  }, []);

  const fetchBusinessInfoInitValues = async () => {
    try {
      setIsLoading(true);
      let _initValues: BusinessAccountCompanyInfoFormType | null = null;
      if (!isEmpty(businessStructure?.business)) {
        _initValues = convertAlreadyExistingBusinessInfoToFormData(businessStructure?.business);
      } else {
        const response = await queryCompaniesControllerBusinessInfo({
          companyId: currentUser?.companyId || "",
        });
        _initValues = getBusinessInfoFormDataInitValues(response);
      }

      setInitValues(_initValues);
    } catch (error) {
      setErrorMessage(getErrorMessageFromResponse(error));
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      });
    }
  };

  const handleSubmit = async ({ values, validateForm, dirty }: IHandleSubmitParams) => {
    try {
      const isEdit = !isEmpty(businessStructure?.business);
      const errors = await validateForm(values);
      if (!isEmpty(errors)) return;

      setIsSubmitting(true);

      if (dirty) {
        if (!isEdit) {
          const payload = convertBusinessFormDataToBusinessAccountData(values);
          await mutationBusinessBankAccountsControllerCreateBusinessBankAccount()(payload);
        } else {
          const payload = convertBusinessAccountCompanyFormToUpdateBusinessDto(
            values as BusinessAccountCompanyInfoFormType,
          );
          await mutationBusinessBankAccountsControllerUpdateBusiness({
            businessStructureBusinessId:
              businessStructure?.business?.businessStructureBusinessId || "",
          })(payload);
        }
        onSubmitSuccess?.(true);
        await getCompanyInfo(currentCompany?.companyId || "", true);
        return;
      }
      onSubmitSuccess?.();
    } catch (error) {
      setErrorMessage(getErrorMessageFromResponse(error));
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    isSubmitting,
    errorMessage,
    initValues,
    handleSubmit,
    isLoading,
  };
};
