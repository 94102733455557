import { Trans } from "react-i18next";
import { t } from "i18next";
import routes from "routes/routes";

import { HELP_EMAIL } from "constants/shared";

import { CustomLinkSmall } from "uikit";

export const getErrorMessageFromResponse = (error?: any) => {
  const result =
    error?.data?.error ||
    (typeof error === "string" && error) ||
    (typeof error?.message === "string" && error?.message) ||
    (typeof error?.error === "string" && error?.error) ||
    "";

  return result;
};

export const getTranslatedErrorMessageFromResponse = (
  error?: any,
  values?: Record<string, string>,
) => {
  const errorMessage = getErrorMessageFromResponse(error);

  return (
    <Trans
      i18nKey={`errors.${errorMessage}`}
      values={{ helpEmail: HELP_EMAIL, ...values }}
      defaults={t("errors.defaultMessage")}
    >
      {" "}
      <CustomLinkSmall href={`mailto: ${HELP_EMAIL}`}>{HELP_EMAIL}</CustomLinkSmall>
      <CustomLinkSmall href={routes.RECOVERY_ACCOUNT}></CustomLinkSmall>
    </Trans>
  );
};
