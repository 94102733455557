import { FC } from "react";
import { Trans } from "react-i18next";
import { PlusThin } from "assets/svg";
import { Formik } from "formik";

import { SyncteraAccountBalanceType } from "types/BETypes";
import DashboardLayout from "layouts/DashboardLayout";
import BankAccountInfo from "pages/Banking/shared/components/BankAccountInfo";
import { IBankAccountNumber } from "pages/Banking/shared/components/BankAccountInfo/types";
import BankingDisclosureComponent from "components/BankingDisclosureComponent";

import { FullScreenLoader, SectionTitle } from "uikit";

import { BankingDisclosureContainer } from "../../shared/styles";
import {
  ManualBankAccountAdditionalInfoModal,
  TransferConfirmationModal,
  TransferFundsForm,
  TransferSuccessModal,
} from "./components";
import {
  TransferFundsFormType,
  TransferFundsFormValidationSchema,
} from "./components/TransferFundsForm/validationSchema";
import { useTransferFundsPage } from "./useTransferFundsPage";
import {
  BackButton,
  BankAccountEmptyStateButton,
  BankAccountEmptyStateMessage,
  BankAccountEmptyStateSection,
  ButtonsContainer,
  Container,
  PaymentSection,
  StyledSectionInner,
} from "./styles";

interface IProps {}

const TransferFundsPage: FC<IProps> = () => {
  const { metadata, pageData, actions, modals } = useTransferFundsPage();
  const { translationPrefix } = metadata;
  const paymentTranslationPrefix = `${translationPrefix}.payment_details_section`;

  const renderBankAccountEmptyState = () => {
    return (
      <BankAccountEmptyStateSection>
        <BankAccountEmptyStateMessage>
          <Trans i18nKey={`${paymentTranslationPrefix}.empty_state.message`} />
        </BankAccountEmptyStateMessage>
        <BankAccountEmptyStateButton
          onClick={pageData.paymentDetails.bankAccountSelectProps.onAddAccount}
        >
          <PlusThin />
          <Trans i18nKey={`${paymentTranslationPrefix}.empty_state.connect_bank_account_btn`} />
        </BankAccountEmptyStateButton>
      </BankAccountEmptyStateSection>
    );
  };

  const renderBankAccountSection = () => {
    const amount =
      (pageData.bankAccountDetails?.bankAccountDetails?.balances || []).find(
        (item) => item.type === SyncteraAccountBalanceType.AVAILABLE_BALANCE,
      )?.balance || 0;
    const componentTranslationPrefix = `banking_pages.shared.components.bank_account_info`;

    const BANumbers: IBankAccountNumber[] = [
      {
        id: "ba_routing_number_label",
        title: <Trans i18nKey={`${componentTranslationPrefix}.routing_number_label`} />,
        number: pageData.bankAccountDetails.bankAccountDetails?.bankRouting || "",
        isHidden: !!pageData.bankAccountDetails.bankAccountNumbersState.routingNumberHidden,
        onShowToggle: () => {
          pageData.bankAccountDetails.setBankAccountNumbersState({
            ...pageData.bankAccountDetails.bankAccountNumbersState,
            routingNumberHidden:
              !pageData.bankAccountDetails.bankAccountNumbersState.routingNumberHidden,
          });
        },
      },
      {
        id: "ba_account_number_label",
        title: <Trans i18nKey={`${componentTranslationPrefix}.account_number_label`} />,
        number: pageData.bankAccountDetails.bankAccountDetails?.accountNumber || "",
        isHidden: !!pageData.bankAccountDetails.bankAccountNumbersState.accountNumberHidden,
        onShowToggle: () => {
          pageData.bankAccountDetails.setBankAccountNumbersState({
            ...pageData.bankAccountDetails.bankAccountNumbersState,
            accountNumberHidden:
              !pageData.bankAccountDetails.bankAccountNumbersState.accountNumberHidden,
          });
        },
      },
    ];

    return <BankAccountInfo amount={amount} items={BANumbers} />;
  };

  return (
    <DashboardLayout>
      {(metadata.isLoading || metadata.isLoadingBankAccounts) && <FullScreenLoader />}
      <Container>
        {renderBankAccountSection()}

        <PaymentSection>
          <SectionTitle>
            <Trans i18nKey={`${paymentTranslationPrefix}.title`} />
          </SectionTitle>

          <StyledSectionInner>
            {pageData.paymentDetails.bankAccountSelectProps.bankAccountsList.length === 0 ? (
              renderBankAccountEmptyState()
            ) : (
              <Formik<TransferFundsFormType>
                onSubmit={(values) => actions.handleOpenConfirmationModal(values)}
                validationSchema={TransferFundsFormValidationSchema}
                initialValues={{
                  amount: "",
                  description: "",
                }}
                validateOnChange={metadata.svoc}
              >
                {(formikProps) => (
                  <>
                    <TransferFundsForm
                      originTranslationPrefix={translationPrefix}
                      bankAccountSelectProps={pageData.paymentDetails.bankAccountSelectProps}
                      companyBankAccount={pageData.bankAccountDetails.bankAccountDetails}
                      formikProps={formikProps}
                      setSvoc={metadata.setSvoc}
                    />
                    <TransferConfirmationModal
                      originTranslationPrefix={paymentTranslationPrefix}
                      fromAccountNumber={
                        pageData.bankAccountDetails.bankAccountDetails?.accountNumber || ""
                      }
                      toAccountNumber={
                        pageData.paymentDetails.bankAccountSelectProps.selectedBankAccount
                          ?.last4Digits || ""
                      }
                      amount={formikProps.values.amount}
                      onClose={() => modals.transferConfirmationModal.setModal(false)}
                      onSubmit={actions.handleSubmitTransferFunds}
                      isOpen={modals.transferConfirmationModal.isOpen}
                      onVerifyCodeResend={actions.handleVerificationCodeResend}
                    />
                    <TransferSuccessModal
                      originTranslationPrefix={paymentTranslationPrefix}
                      fromAccountNumber={
                        pageData.bankAccountDetails.bankAccountDetails?.accountNumber || ""
                      }
                      toAccountNumber={
                        pageData.paymentDetails.lastTransferredBankAccount?.last4Digits || ""
                      }
                      amount={formikProps.values.amount}
                      onClose={() => {
                        modals.transferSuccessModal.setModal(false);
                        formikProps.resetForm();
                      }}
                      isOpen={modals.transferSuccessModal.isOpen}
                    />
                    <ManualBankAccountAdditionalInfoModal
                      originTranslationPrefix={paymentTranslationPrefix}
                      isOpen={modals.manualBankAccountAdditionalInfoModal.isOpen}
                      onClose={() => modals.manualBankAccountAdditionalInfoModal.setModal(false)}
                      onSubmit={(values) => actions.handleOpenConfirmationModal(undefined, values)}
                      bankAccountInfo={
                        pageData.paymentDetails.bankAccountSelectProps.selectedBankAccount
                      }
                    />
                  </>
                )}
              </Formik>
            )}
          </StyledSectionInner>
        </PaymentSection>

        <ButtonsContainer>
          <BackButton
            onClick={actions.handleBack}
            disabled={metadata.isLoading || metadata.isLoadingBankAccounts}
          >
            <Trans i18nKey={`buttons.back`} />
          </BackButton>
        </ButtonsContainer>

        <BankingDisclosureContainer>
          <BankingDisclosureComponent />
        </BankingDisclosureContainer>
      </Container>
    </DashboardLayout>
  );
};

export default TransferFundsPage;
