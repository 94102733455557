import { Trans } from "react-i18next";

import { IVerticalStepListItem } from "components/VerticalStepsList/types";

import { ADMIN_SETUP_STEPS_NUMBER } from "./constants";

export const getAdminSetupStepsDescriptionsList = (
  translationPrefix: string,
): IVerticalStepListItem[] => {
  const steps = Array.from({ length: ADMIN_SETUP_STEPS_NUMBER }, (value, index) => {
    return {
      index: index + 1,
      title: <Trans i18nKey={`${translationPrefix}.${index}.title`} />,
      description: <Trans i18nKey={`${translationPrefix}.${index}.description`} />,
    };
  });

  return steps;
};
