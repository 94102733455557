import { useContext, useEffect, useMemo, useState } from "react";
import { useAppSelector } from "store/hooks";
import { userMetadataSelector } from "store/selectors";

import { ErrorConst, UserRole, UserStatus } from "types/BETypes";
import { hasCompanyAdminRole } from "permissions/helpers/shared";
import { getErrorMessageFromResponse } from "helpers/shared/errors";
import { convertBusinessStructurePersonToUserResponseDto } from "helpers/shared/userData";
import { BusinessOwnersStructureContext } from "components/BusinessOwnersStructure";
import { getBusinessStructureMembers } from "components/BusinessOwnersStructure/helpers";
import {
  IBusinessOwnersStructureDisclosuresType,
  IUseReviewCertifyStepParams,
} from "components/BusinessOwnersStructure/types";
import { useKybVerification } from "components/BusinessOwnersStructure/useKybVerification";

import {
  mutationAdminsControllerInviteTeamMember,
  mutationAdminsControllerResendInvitation,
  UserResponseDto,
} from "utils/swagger_react_query";

export const useReviewCertifyStep = ({
  onKybSuccess,
  onKybStatusSectionPrimaryBtnClick,
}: IUseReviewCertifyStepParams) => {
  const divWrapperId = "review-certify-step-container";

  const {
    isLoading: isDataLoading,
    kybResponseType,
    setKybResponseType,
    setKybErrors,
    kybErrors,
    businessOwnersStructure,
    refetchBusinessOwnersStructure,
  } = useContext(BusinessOwnersStructureContext);

  const currentUser = useAppSelector(userMetadataSelector);
  const [isLoading, setIsLoading] = useState<boolean>(isDataLoading || false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [selectedTeamMember, setSelectedTeamMember] = useState<Partial<UserResponseDto> | null>(
    null,
  );
  const [inviteSent, setInviteSent] = useState<boolean>(false);

  const [certificationDisclosureValues, setCertificationDisclosureValues] = useState({
    [IBusinessOwnersStructureDisclosuresType.CERTIFICATION]: false,
    [IBusinessOwnersStructureDisclosuresType.TERMS_AND_CONDITIONS]: false,
  });
  const [kybSubmissionState, setKybSubmissionState] = useState({
    showWarning: false,
    showDropdown: false,
  });

  const { handleKybSubmit, handleStatusSectionPrimaryBtnClick } = useKybVerification({
    setIsSubmitting,
    isSubmitting,
    errorMessage,
    setErrorMessage,
    onKybSuccess,
    onKybStatusSectionPrimaryBtnClick,
  });

  useEffect(() => {
    fetchData();
    return () => {
      setKybResponseType(null);
      setKybErrors([]);
    };
  }, []);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      await refetchBusinessOwnersStructure();
    } catch (error) {
      setErrorMessage(getErrorMessageFromResponse(error));
      scrollToTopOfSection();
    } finally {
      setIsLoading(false);
    }
  };

  const scrollToTopOfSection = () => {
    const divContainer = document.getElementById(divWrapperId);
    if (divContainer) {
      divContainer?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const businessStructureMembers: Partial<UserResponseDto>[] = useMemo(
    () =>
      getBusinessStructureMembers(businessOwnersStructure || null).map((it) =>
        convertBusinessStructurePersonToUserResponseDto(it),
      ),
    [businessOwnersStructure],
  );

  const handleShowWarning = () => {
    setKybSubmissionState((prevState) => ({
      ...prevState,
      showWarning: !prevState.showWarning,
      showDropdown: false,
    }));
  };

  const handleShowDropdown = () => {
    setKybSubmissionState((prevState) => ({
      ...prevState,
      showWarning: !prevState.showDropdown ? false : prevState.showWarning,
      showDropdown: !prevState.showDropdown,
    }));
  };

  const handleChangeSelectedTeamMember = (data: Partial<UserResponseDto>) => {
    setErrorMessage(null);
    setSelectedTeamMember(data);
  };

  const handleCertificationDisclosureValuesChange = (
    type: IBusinessOwnersStructureDisclosuresType,
  ) => {
    setCertificationDisclosureValues((prevState) => ({
      ...prevState,
      [type]: !prevState[type],
    }));
  };

  const handleSubmit = async () => {
    try {
      if (kybResponseType) {
        handleStatusSectionPrimaryBtnClick();
      } else {
        await handleKybSubmit();
      }
    } catch (error: any) {
      if (error.message === ErrorConst.CANNOT_ACCEPT_DISCLOSURE) {
        handleShowWarning();
      }
    }
  };

  const handleInviteTeamMember = async () => {
    console.log(selectedTeamMember);
    try {
      setIsSubmitting(true);
      if (
        selectedTeamMember?.status === UserStatus.ACTIVE &&
        hasCompanyAdminRole(selectedTeamMember)
      ) {
        setErrorMessage(ErrorConst.ADMIN_ALREADY_ACTIVE_KYB);
        scrollToTopOfSection();
        return;
      } else if (!hasCompanyAdminRole(selectedTeamMember)) {
        await mutationAdminsControllerInviteTeamMember()({
          email: selectedTeamMember?.email || "",
          companyRole: UserRole.ADMIN,
        });
      } else {
        await mutationAdminsControllerResendInvitation()({
          email: selectedTeamMember?.email || "",
        });
      }

      setInviteSent(true);
    } catch (error: any) {
      setErrorMessage(getErrorMessageFromResponse(error));
      scrollToTopOfSection();
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    metadata: {
      currentUser,
      isLoading,
      isSubmitting,
      inviteSent,
      errorMessage,
      divWrapperId,
    },
    pageData: {
      kybErrors,
      kybResponseType,
      businessOwnersStructure,
      certificationDisclosureValues,
      kybSubmissionState,
      selectedTeamMember,
      businessStructureMembers,
    },
    actions: {
      handleCertificationDisclosureValuesChange,
      handleSubmit,
      handleShowWarning,
      handleShowDropdown,
      handleInviteTeamMember,
      handleChangeSelectedTeamMember,
    },
  };
};
