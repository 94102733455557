import * as yup from "yup";

import {
  addressValidationRulesBase,
  businessInformationValidationRulesAdditional,
  commonValidationRules,
  userDetailsValidationRulesBase,
} from "constants/shared/validationRules";

export const IndividualOwnerFormValidationSchema = yup.object({
  ...addressValidationRulesBase,
  ...userDetailsValidationRulesBase,
  email: commonValidationRules.email,
  ownershipPercentage: businessInformationValidationRulesAdditional.ownershipPercentage,
});

export type IndividualOwnerFormType = yup.InferType<typeof IndividualOwnerFormValidationSchema>;
