import { useContext, useEffect, useState } from "react";
import { FormikProps } from "formik";
import { isEmpty } from "lodash";
import { useAppSelector } from "store/hooks";
import { userMetadataSelector } from "store/selectors";

import { getErrorMessageFromResponse } from "helpers/shared/errors";
import useAuth from "hooks/useAuth";
import { BusinessOwnersStructureContext } from "components/BusinessOwnersStructure";
import { EKybResponseType } from "components/BusinessOwnersStructure/types";

import {
  BusinessOwnerPersonData,
  BusinessStructureBusinessOwnersResponseDto,
  BusinessStructureKeyManagerResponseDto,
  BusinessStructureOwnerResponseDto,
  CreateBusinessOwnerBusiness,
  CreateKeyManager,
  mutationBusinessBankAccountsControllerCreateBusinessKeyManager,
  mutationBusinessBankAccountsControllerCreateBusinessOwnerBusiness,
  mutationBusinessBankAccountsControllerCreateBusinessOwnerPerson,
  mutationBusinessBankAccountsControllerDeleteBusiness,
  mutationBusinessBankAccountsControllerDeletePerson,
  mutationBusinessBankAccountsControllerUpdateBusiness,
  mutationBusinessBankAccountsControllerUpdatePerson,
  queryBusinessBankAccountsControllerGetKybErrors,
  UserResponseDto,
} from "utils/swagger_react_query";

import { BusinessInformationFormType } from "../../components/BusinessInformationForm/validationSchema";
import { BusinessKeyManagerFormType } from "../../components/BusinessKeyManagerForm/validationSchema";
import { BusinessStructureEntityType } from "../../components/BusinessStructureEntityCard/types";
import { IndividualOwnerFormType } from "../../components/IndividualOwnerForm/validationSchema";
import {
  convertBusinessFormDataToResponseDto,
  convertBusinessFormToReqPayload,
  convertIndividualOwnerFormToReqPayload,
  convertKeyManagerFormToReqPayload,
  convertUserResponseToIndividualOwnerDto,
  convertUserResponseToKeyManagerDto,
  getAllKeyManagers,
} from "../../helpers";

export const useBusinessOwnersStructureStep = () => {
  const currentUser = useAppSelector(userMetadataSelector);
  const { getCurrentUser } = useAuth();
  const {
    businessOwnersStructure,
    refetchBusinessOwnersStructure,
    setBusinessOwnersStructure,
    kybResponseType,
    setKybErrors,
  } = useContext(BusinessOwnersStructureContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [selectedEntityType, setSelectedEntityType] = useState<BusinessStructureEntityType | null>(
    null,
  );
  const [isEntityFormSubmitting, setIsEntityFormSubmitting] = useState<boolean>(false);
  const [businessFormValues, setBusinessFormValues] = useState<BusinessInformationFormType | null>(
    null,
  );
  const [showEntityForm, setShowEntityForm] = useState<boolean>(false);
  const [entityDataInProcess, setEntityDataInProcess] = useState<
    | BusinessStructureKeyManagerResponseDto
    | BusinessStructureOwnerResponseDto
    | BusinessStructureBusinessOwnersResponseDto
    | null
  >(null);

  useEffect(() => {
    fetchKybErrors();
  }, [kybResponseType]);

  const fetchKybErrors = async () => {
    if (
      ![
        EKybResponseType.ERROR,
        EKybResponseType.ERROR_BANK_ACCOUNT_CREATED,
        EKybResponseType.INCORRECT_INFO,
      ].includes(kybResponseType as EKybResponseType)
    )
      return;

    try {
      setIsLoading(true);
      const kybErrorsRes = await queryBusinessBankAccountsControllerGetKybErrors();
      setKybErrors(kybErrorsRes);
    } catch (error) {
      _handleShowRequestError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const _handleShowRequestError = (error: any) => {
    const _error = getErrorMessageFromResponse(error);
    setErrorMessage?.(_error);
  };

  const _handleCloseForm = () => {
    setEntityDataInProcess(null);
    setShowEntityForm(false);
    setSelectedEntityType(null);
  };

  const handleSelectEntityClick = (entityType: BusinessStructureEntityType) => {
    if (entityType === BusinessStructureEntityType.BUSINESS) {
      handleOpenEntityForm(undefined);
    }
    setSelectedEntityType(entityType);
  };

  const handleSetupEntityBackClick = () => {
    setErrorMessage?.("");
    //edit flow
    if (
      entityDataInProcess &&
      (("businessStructureBusinessId" in entityDataInProcess &&
        entityDataInProcess.businessStructureBusinessId.length) ||
        ("businessStructurePersonId" in entityDataInProcess &&
          entityDataInProcess.businessStructurePersonId.length))
    ) {
      _handleCloseForm();
      return;
    }

    //create flow
    if (showEntityForm) {
      // Returns to the search step from key manager flow and individual owner flow
      setShowEntityForm(false);
      setEntityDataInProcess(null);

      if (selectedEntityType === BusinessStructureEntityType.BUSINESS) {
        // Returns to the list view from the business form
        setSelectedEntityType(null);
        setBusinessFormValues(null);
      }
    } else if (selectedEntityType && !showEntityForm && !businessFormValues) {
      // Returns to the list view from the search step
      setSelectedEntityType(null);
    } else if (
      selectedEntityType === BusinessStructureEntityType.KEY_MANAGER &&
      businessFormValues
    ) {
      // Returns to the business form from the search step
      const data = convertBusinessFormDataToResponseDto(businessFormValues);
      setBusinessFormValues(null);
      setEntityDataInProcess(data);
      setShowEntityForm(true);
      setSelectedEntityType(BusinessStructureEntityType.BUSINESS);
    }
  };

  const handleOpenEditEntityForm = (entityId: string, entityType: BusinessStructureEntityType) => {
    if (entityType === BusinessStructureEntityType.BUSINESS) {
      const entityData = businessOwnersStructure?.businessOwners?.find(
        (owner) => owner.businessStructureBusinessId === entityId,
      );
      if (entityType === BusinessStructureEntityType.BUSINESS && entityData) {
        setSelectedEntityType(BusinessStructureEntityType.BUSINESS);
        setShowEntityForm(true);
        setEntityDataInProcess(entityData);
      }
    }

    if (entityType === BusinessStructureEntityType.OWNER) {
      const entityData = businessOwnersStructure?.owners?.find(
        (owner) => owner.businessStructurePersonId === entityId,
      );
      if (entityType === BusinessStructureEntityType.OWNER && entityData) {
        setSelectedEntityType(BusinessStructureEntityType.OWNER);

        setShowEntityForm(true);
        setEntityDataInProcess(entityData);
      }
    }

    if (entityType === BusinessStructureEntityType.KEY_MANAGER) {
      const allKeyManagers = getAllKeyManagers(businessOwnersStructure);
      const entityData = allKeyManagers?.find(
        (keyManager) => keyManager.businessStructurePersonId === entityId,
      );
      if (entityType === BusinessStructureEntityType.KEY_MANAGER && entityData) {
        setSelectedEntityType(BusinessStructureEntityType.KEY_MANAGER);
        setShowEntityForm(true);
        setEntityDataInProcess(entityData);
      }
    }
  };

  const handleOpenEntityForm = (selectedUser: UserResponseDto | undefined) => {
    if (selectedEntityType === BusinessStructureEntityType.OWNER && selectedUser) {
      const convertedData = convertUserResponseToIndividualOwnerDto(selectedUser);
      setEntityDataInProcess(convertedData);
    }
    if (selectedEntityType === BusinessStructureEntityType.KEY_MANAGER && selectedUser) {
      const convertedData = convertUserResponseToKeyManagerDto(selectedUser);
      setEntityDataInProcess(convertedData);
    }

    if (selectedEntityType === BusinessStructureEntityType.BUSINESS) {
      setEntityDataInProcess(null);
    }

    setShowEntityForm(true);
  };

  const handleEditEntityFormSubmit = async (
    entityId: string,
    formikProps: FormikProps<
      IndividualOwnerFormType | BusinessKeyManagerFormType | BusinessInformationFormType
    >,
  ) => {
    try {
      const { values, validateForm, dirty } = formikProps;
      const errors = await validateForm(values);
      if (!isEmpty(errors)) return;

      setIsEntityFormSubmitting(true);
      if (selectedEntityType === BusinessStructureEntityType.BUSINESS) {
        const businessKeyManagerData = businessOwnersStructure?.businessOwners?.find(
          (it) => it.businessStructureBusinessId === entityId,
        )?.relatedKeyManager;

        if (dirty) {
          const payload = convertBusinessFormToReqPayload(values as BusinessInformationFormType);
          await mutationBusinessBankAccountsControllerUpdateBusiness({
            businessStructureBusinessId: entityId || "",
          })(payload);
        }

        if (businessKeyManagerData !== undefined) {
          setSelectedEntityType(null);
          setShowEntityForm(false);

          await refetchBusinessOwnersStructure();
          handleOpenEditEntityForm(
            businessKeyManagerData?.businessStructurePersonId || "",
            BusinessStructureEntityType.KEY_MANAGER,
          );

          return;
        }
      }

      if (
        selectedEntityType &&
        [BusinessStructureEntityType.KEY_MANAGER, BusinessStructureEntityType.OWNER].includes(
          selectedEntityType,
        )
      ) {
        if (dirty) {
          let payload: CreateKeyManager | BusinessOwnerPersonData | {} = {};
          if (selectedEntityType === BusinessStructureEntityType.OWNER) {
            payload = convertIndividualOwnerFormToReqPayload(values as IndividualOwnerFormType);
          }
          if (selectedEntityType === BusinessStructureEntityType.KEY_MANAGER) {
            payload = convertKeyManagerFormToReqPayload(values as BusinessKeyManagerFormType);
          }

          await mutationBusinessBankAccountsControllerUpdatePerson({
            businessStructurePersonId: entityId || "",
          })(payload);
        }
      }

      if (dirty) {
        await refetchBusinessOwnersStructure();
      }

      _handleCloseForm();
    } catch (error) {
      _handleShowRequestError(error);
    } finally {
      setIsEntityFormSubmitting(false);
    }
  };

  const handleEntityFormSubmit = async ({
    values,
    validateForm,
  }: FormikProps<
    IndividualOwnerFormType | BusinessKeyManagerFormType | BusinessInformationFormType
  >) => {
    setErrorMessage(null);
    const errors = await validateForm(values);
    if (!isEmpty(errors)) return;
    try {
      setIsEntityFormSubmitting(true);
      if (
        selectedEntityType === BusinessStructureEntityType.BUSINESS ||
        (selectedEntityType === BusinessStructureEntityType.KEY_MANAGER &&
          !isEmpty(businessFormValues))
      ) {
        if (businessFormValues) {
          const payload: CreateBusinessOwnerBusiness = {
            business: convertBusinessFormToReqPayload(businessFormValues),
            keyManager: {
              ...convertKeyManagerFormToReqPayload(values as BusinessKeyManagerFormType),
            },
          };
          await mutationBusinessBankAccountsControllerCreateBusinessOwnerBusiness()(payload);

          _handleCloseForm();
          setBusinessFormValues(null);
          await refetchBusinessOwnersStructure();
        } else {
          setBusinessFormValues(values as BusinessInformationFormType);
          setSelectedEntityType(BusinessStructureEntityType.KEY_MANAGER);
          setShowEntityForm(false);
        }
        return;
      }
      if (selectedEntityType === BusinessStructureEntityType.KEY_MANAGER) {
        const payload = {
          ...convertKeyManagerFormToReqPayload(values as BusinessKeyManagerFormType),
        };
        await mutationBusinessBankAccountsControllerCreateBusinessKeyManager()(payload);
        _handleCloseForm();
        await refetchBusinessOwnersStructure();
        return;
      }
      if (selectedEntityType === BusinessStructureEntityType.OWNER) {
        const payload = {
          ...convertIndividualOwnerFormToReqPayload(values as IndividualOwnerFormType),
        };
        await mutationBusinessBankAccountsControllerCreateBusinessOwnerPerson()(payload);
        _handleCloseForm();
        await refetchBusinessOwnersStructure();
        return;
      }
    } catch (error) {
      _handleShowRequestError(error);
    } finally {
      setIsEntityFormSubmitting(false);
    }
  };

  const handleDeleteEntity = async (entityId: string, entityType: BusinessStructureEntityType) => {
    try {
      setIsLoading(true);
      if (entityType === BusinessStructureEntityType.BUSINESS) {
        await mutationBusinessBankAccountsControllerDeleteBusiness({
          businessStructureBusinessId: entityId || "",
        })();
      } else if (
        [BusinessStructureEntityType.KEY_MANAGER, BusinessStructureEntityType.OWNER].includes(
          entityType,
        )
      ) {
        await mutationBusinessBankAccountsControllerDeletePerson({
          businessStructurePersonId: entityId || "",
          personType: entityType as any,
        })();
      }
      await refetchBusinessOwnersStructure();
    } catch (error) {
      _handleShowRequestError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    refetchBusinessOwnersStructure,
    metadata: {
      isLoading,
      selectedEntityType,
      showEntityForm,
      isEntityFormSubmitting,
    },
    pagedData: {
      businessOwnersStructure,
      entityDataInProcess,
      errorMessage,
    },
    actions: {
      handleSelectEntityClick,
      handleSetupEntityBackClick,
      handleOpenEntityForm,
      handleEntityFormSubmit,
      handleOpenEditEntityForm,
      handleDeleteEntity,
      handleEditEntityFormSubmit,
      handleSetBusinessStructure: setBusinessOwnersStructure,
    },
  };
};
