import {
  EBusinessStructureUserStatus,
  SalsaWorkerPaymentReadinessEnum,
  UserStatus,
} from "types/BETypes";

import { UserResponseDto } from "utils/swagger_react_query";

export enum EComprehensibleUserStatuses {
  EMPLOYED = "EMPLOYED",
  PENDING_EXIT = "PENDING_EXIT",
  EXITED = "EXITED",
  IN_DEFAULT = "IN_DEFAULT",
  PROSPECT = "PROSPECT",
  INVITED = "INVITED",
  NOT_READY = "NOT_READY",
}

export const StatusToComprehensibleMap = {
  //[UserStatus.PROSPECT]: EComprehensibleUserStatuses.EMPLOYED,
  [UserStatus.PROSPECT]: EComprehensibleUserStatuses.PROSPECT,
  [UserStatus.INVITED]: EComprehensibleUserStatuses.INVITED,
  [UserStatus.ACTIVE]: EComprehensibleUserStatuses.EMPLOYED,
  [UserStatus.PENDING_EXIT]: EComprehensibleUserStatuses.PENDING_EXIT,
  [UserStatus.EXITED]: EComprehensibleUserStatuses.EXITED,
  [UserStatus.NO_BALANCE_DUE]: EComprehensibleUserStatuses.EXITED,
  [UserStatus.IN_DEFAULT]: EComprehensibleUserStatuses.IN_DEFAULT,
};

export const getComprehensibleStatus = (user: Partial<UserResponseDto> | null | undefined) => {
  let result = StatusToComprehensibleMap[user?.status as UserStatus];

  if (
    user?.status === UserStatus.ACTIVE &&
    (user?.salsaPaymentReadiness === SalsaWorkerPaymentReadinessEnum.NOT_READY ||
      user?.salsaPaymentReadiness === SalsaWorkerPaymentReadinessEnum.PARTIALLY_READY)
  ) {
    result = EComprehensibleUserStatuses.NOT_READY;
  }

  return result;
};

export const getComprehensibleBusinessStructureUserStatus = (user: Partial<UserResponseDto>) => {
  const comprehensibleUserStatus = getComprehensibleStatus(user);

  const isUserAuthorizedButWithoutAccount = !user.status;
  let result: EBusinessStructureUserStatus | undefined = undefined;

  if (isUserAuthorizedButWithoutAccount) {
    result = EBusinessStructureUserStatus.NOT_INVITED;
  } else if (user.status === UserStatus.ACTIVE) {
    result = EBusinessStructureUserStatus.ACTIVE;
  } else if (comprehensibleUserStatus === EComprehensibleUserStatuses.INVITED) {
    result = EBusinessStructureUserStatus.INVITED;
  }

  return result;
};
