import { FC } from "react";
import { Trans } from "react-i18next";
import { BuildingIcon, ModalUser, UserStarIcon } from "assets/svg";
import { isEmpty } from "lodash";

import {
  BusinessOwnersStructureWidgetType,
  IBusinessOwnersStructureStepProps,
} from "components/BusinessOwnersStructure/types";

import { Emphasized } from "uikit";

import {
  BusinessStructureBusinessOwnersResponseDto,
  BusinessStructureKeyManagerResponseDto,
  BusinessStructureOwnerResponseDto,
  GetBusinessStructureResponseDto,
} from "utils/swagger_react_query";

import { BusinessStructureEntityCard } from "../BusinessStructureEntityCard";
import { BusinessStructureEntityType } from "../BusinessStructureEntityCard/types";
import {
  SDescription,
  Section,
  SHeader,
  SHeaderButtonsContainer,
  SHeaderTextContainer,
  STitle,
} from "../styles";
import { CardsContainer, Container, StyledSecondaryButtonIconed } from "./styles";
interface IProps extends Pick<IBusinessOwnersStructureStepProps, "widgetType"> {
  businessOwnersStructure?: GetBusinessStructureResponseDto | null;
  onSelectEntity?: (entityType: BusinessStructureEntityType) => void;
  onDelete?: (entityId: string, entityType: BusinessStructureEntityType) => void;
  onEdit?: (entityId: string, entityType: BusinessStructureEntityType) => void;
}

export const StructureListSection: FC<IProps> = ({
  businessOwnersStructure,
  widgetType,
  onSelectEntity,
  onDelete,
  onEdit,
}) => {
  const translationPrefix = `components.business_owner_structure.business_structure_entity_card`;

  const renderList = (
    data:
      | BusinessStructureOwnerResponseDto[]
      | BusinessStructureKeyManagerResponseDto[]
      | BusinessStructureBusinessOwnersResponseDto[]
      | undefined,
    entityType: BusinessStructureEntityType,
  ) => {
    if (isEmpty(data) && widgetType === BusinessOwnersStructureWidgetType.VIEW) return;
    if (isEmpty(data))
      return (
        <BusinessStructureEntityCard
          widgetType={widgetType}
          emptyCardEntityType={entityType}
          onEmptyCardClick={() => onSelectEntity?.(entityType)}
        />
      );

    return data?.map((data) => (
      <BusinessStructureEntityCard
        widgetType={widgetType}
        data={data}
        onDelete={onDelete}
        onEdit={onEdit}
      />
    ));
  };

  const keyManagersList = [...(businessOwnersStructure?.keyManagers || [])].filter(
    (it): it is BusinessStructureKeyManagerResponseDto => it !== undefined,
  );

  const shouldRenderOwnersSection =
    widgetType === BusinessOwnersStructureWidgetType.VIEW
      ? !isEmpty(businessOwnersStructure?.owners) ||
        !isEmpty(businessOwnersStructure?.businessOwners)
      : true;

  const shouldRenderManagersSection =
    widgetType === BusinessOwnersStructureWidgetType.VIEW
      ? !isEmpty(businessOwnersStructure?.keyManagers)
      : true;

  const shouldRenderActionButtonsSection = widgetType === BusinessOwnersStructureWidgetType.SETUP;
  const shouldRenderAddIndividualOwnerButton = !!businessOwnersStructure?.owners?.length;
  const shouldRenderAddBusinessOwnerButton = !!businessOwnersStructure?.businessOwners?.length;
  const shouldRenderAddKeyManagerButton = !!keyManagersList?.length;

  return (
    <Container>
      {shouldRenderOwnersSection && (
        <Section>
          <SHeader>
            <SHeaderTextContainer>
              <STitle>
                <Trans i18nKey={`${translationPrefix}.section_title_owners_title`} />
              </STitle>
              <SDescription>
                <Trans
                  i18nKey={`${translationPrefix}.section_title_owners_description`}
                  components={{ 1: <Emphasized /> }}
                />
              </SDescription>
            </SHeaderTextContainer>

            {shouldRenderActionButtonsSection && (
              <SHeaderButtonsContainer>
                {shouldRenderAddIndividualOwnerButton && (
                  <StyledSecondaryButtonIconed
                    onClick={() => onSelectEntity?.(BusinessStructureEntityType.OWNER)}
                    data-testid="add-individual-owner-button"
                  >
                    <ModalUser className={"user"} />
                    <Trans
                      i18nKey={`${translationPrefix}.section_title_owners_add_individual_owner_btn`}
                    />
                  </StyledSecondaryButtonIconed>
                )}

                {shouldRenderAddBusinessOwnerButton && (
                  <StyledSecondaryButtonIconed
                    onClick={() => onSelectEntity?.(BusinessStructureEntityType.BUSINESS)}
                    data-testid="add-business-owner-button"
                  >
                    <BuildingIcon className={"user"} />
                    <Trans
                      i18nKey={`${translationPrefix}.section_title_owners_add_business_owner_btn`}
                    />
                  </StyledSecondaryButtonIconed>
                )}
              </SHeaderButtonsContainer>
            )}
          </SHeader>

          <CardsContainer>
            {renderList(businessOwnersStructure?.owners, BusinessStructureEntityType.OWNER)}

            {renderList(
              businessOwnersStructure?.businessOwners,
              BusinessStructureEntityType.BUSINESS,
            )}
          </CardsContainer>
        </Section>
      )}

      {shouldRenderManagersSection && (
        <Section>
          <SHeader>
            <SHeaderTextContainer>
              <STitle>
                <Trans i18nKey={`${translationPrefix}.section_title_managers_title`} />
              </STitle>
              <SDescription>
                <Trans i18nKey={`${translationPrefix}.section_title_managers_description`} />
              </SDescription>
            </SHeaderTextContainer>

            {shouldRenderActionButtonsSection && shouldRenderAddKeyManagerButton && (
              <SHeaderButtonsContainer>
                <StyledSecondaryButtonIconed
                  onClick={() => onSelectEntity?.(BusinessStructureEntityType.KEY_MANAGER)}
                  data-testid="add-key-manager-button"
                >
                  <UserStarIcon className={"user-star"} />
                  <Trans
                    i18nKey={`${translationPrefix}.section_title_managers_add_key_manager_btn`}
                  />
                </StyledSecondaryButtonIconed>
              </SHeaderButtonsContainer>
            )}
          </SHeader>
          <CardsContainer>
            {renderList(keyManagersList, BusinessStructureEntityType.KEY_MANAGER)}
          </CardsContainer>
        </Section>
      )}
    </Container>
  );
};
