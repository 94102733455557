import { BusinessKeyManagerTitle } from "types/BETypes";
import { EBusinessStructureUserSearchWarning } from "components/AsyncUserSearchInput";
import {
  BusinessStructureEntityCardState,
  BusinessStructureEntityType,
} from "components/BusinessOwnersStructure/components/BusinessStructureEntityCard/types";

export const businessOwnerStructure = {
  "en-US": {
    shared: {
      key_manager_title: {
        [BusinessKeyManagerTitle.DIRECTOR]: "Director",
        [BusinessKeyManagerTitle.OFFICER]: "Officer",
        [BusinessKeyManagerTitle.FOUNDER]: "Founder",
      },
      inputs_business: {
        email: {
          label: "Email",
          errors: {},
        },
        company_name: {
          label: "Company Name",
          errors: {},
        },
        operating_name: {
          label: "Operating Name",
          errors: {},
        },
        ownership_percentage: {
          label: "Ownership Percentage",
          errors: {},
        },
        business_formation_date: {
          label: "Date of Business Formation",
          errors: {},
        },
        state_of_incorporation: {
          label: "State of Incorporation",
          errors: {},
        },
        business_structure: {
          label: "Business Structure",
          placeholder: "Enter Type",
          errors: {},
        },
        business_phone: {
          label: "Business Phone Number",
          errors: {},
        },
        business_email: {
          label: "Business Email",
          errors: {},
        },
        ein: {
          label: "Employer Identification Number (EIN)",
          label_short: "EIN",
          placeholder: "Enter Identification Number",
          errors: {},
        },
        address: {
          label: "Address",
          errors: {},
        },
        city: {
          label: "City",
          errors: {},
        },
        state: {
          label: "State",
          errors: {},
        },
        zip: {
          label: "Zip Code",
          errors: {},
        },
        suite: { label: "Suite", errors: {} },
      },
      inputs_person: {
        email: {
          label: "Email",
          errors: {},
        },
        ownership_percentage: {
          label: "Ownership Percentage",
          errors: {},
        },
        role: { label: "Role" },
        first_name: { label: "First Name", errors: {} },
        last_name: { label: "Last Name", errors: {} },
        phone: { label: "Phone Number", errors: {} },
        social_security_number: { label: "Social Security Number", errors: {} },
        birth_date: { label: "Birth Date", errors: {} },
        address: { label: "Address", errors: {} },
        suite: { label: "Suite", errors: {} },
        city: { label: "City", errors: {} },
        state: { label: "State", errors: {} },
        zip: { label: "Zip Code", errors: {} },
      },
      full_address_label: "Full Address",
      entity_type_labels: {
        [BusinessStructureEntityType.OWNER]: "Individual Owner",
        [BusinessStructureEntityType.KEY_MANAGER]: "Key Manager",
        [BusinessStructureEntityType.BUSINESS]: "Business Owner",
      },
    },

    business_structure_entity_card: {
      section_title_owners_title: "Owners",
      section_title_owners_description:
        "Provide information about individuals or businesses that own <1>25% or more</1> of the company.",
      section_title_owners_add_individual_owner_btn: "Add Individual Owner",
      section_title_owners_add_business_owner_btn: "Add Business Owner",
      section_title_managers_title: "Key Manager",
      section_title_managers_description:
        "Provide information about key manager (e.g., a CEO, CFO, COO, Managing Member etc.)",
      section_title_managers_add_key_manager_btn: "Add Key Manager",
      [BusinessStructureEntityCardState.EMPTY]: {
        [BusinessStructureEntityType.OWNER]: {
          title: "Add Individual Owner",
          description: "Personal information for an individual owner",
        },
        [BusinessStructureEntityType.KEY_MANAGER]: {
          title: "Add Manager Information",
        },
        [BusinessStructureEntityType.BUSINESS]: {
          title: "Add Business Owner",
          description: "Information about another business that owns a stake in the company",
        },
      },
      [BusinessStructureEntityCardState.FILLED]: {
        ownership_percentage_text_label: "Ownership Percentage",
      },
    },
    entity_form_section: {
      back_button_text: "Back to Owners & Key Manager",
      search_input: {
        input_title: "Email",
        empty_message: "No matches found. New user profile will be created",
        search_user_error: `This user is not verified. Please select another user. If you have any questions, please contact us: <1>{{HELP_EMAIL}}</1>`,
      },
      [BusinessStructureEntityType.OWNER]: {
        title: "Add Individual Owner",
        description: "Personal information for an individual owner",
      },
      [BusinessStructureEntityType.KEY_MANAGER]: {
        title: "Add Manager Information",
        business_key_manager: {
          title: "Add Business Owner - Business Key Manager",
          description: "Submit information of key manager within business owner.",
        },
      },
      [BusinessStructureEntityType.BUSINESS]: {
        title: "Add Business Owner - Business Information",
        description:
          "Submit details information on any businesses that owns more than 25% of the business",
      },
      send_invite_checkbox: "Send admin invite to this user",
      search_user_warning: {
        [EBusinessStructureUserSearchWarning.ALREADY_ASSIGNED_AS_KEY_MANAGER]: `This user already assigned as key manager. Please select another user.
        If you have any questions, please contact us: <1/>`,
        [EBusinessStructureUserSearchWarning.ALREADY_ASSIGNED_AS_OWNER]: `This user already assigned as business owner. Please select another user.
        If you have any questions, please contact us: <1/>`,
        [EBusinessStructureUserSearchWarning.NOT_VERIFIED]: `This user is not verified. Please select another user. If you have any questions, please contact us: <1/>`,
      },
    },

    review_certify_step: {
      title: "Review & Certify",
      description:
        "Review and accept the terms and conditions to proceed with setting up your Paid Business Account.",
      business_information_section: {
        title: "Business Information",
      },
      company_structure_section: {
        title: "Company Structure",
      },

      disclosures_section: {
        certification_statement:
          "I certify that I am an Owner or Key Manager of the company and have the authority to complete this on behalf of my company. I certify to the best of my knowledge that the information provided is complete and correct.",
        terms_conditions:
          "I agree to the <1>terms and conditions</1> for setting up a Paid Bank Account",
      },
      warning_section: {
        message: `Only a Key Manager or Individual Owner can accept this disclosure. Please invite them to accept this disclosure. If you have any questions please contact our help center <br/>
        <1/>
        `,
        invite_button: "Invite Team Members",
      },
      select_team_member_section: {
        send_invite: "Send Invite",
        select_team_member_label: "Invite Team Members",
      },
    },
  },
};

export default businessOwnerStructure;
