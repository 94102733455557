import styled, { css } from "styled-components";

import { Body2, H3 } from "uikit";

export const iconBtnMixin = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;

  svg {
    width: 24px;
    height: 24px;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const SHeaderTextContainer = styled.div``;

export const STitle = styled(H3)`
  font-size: ${({ theme }) => theme.size.subtitleLarge};
  margin-bottom: 8px;
`;

export const SDescription = styled(Body2)`
  color: ${({ theme }) => theme.colors.textAlt};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  margin-bottom: 16px;

  span {
    color: ${({ theme }) => theme.colors.text};
  }
`;

export const SHeaderButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const EditBtnWithIcon = styled.span`
  ${iconBtnMixin};
  svg {
    path {
      stroke: ${({ theme }) => theme.colors.accentMain};
    }
  }
`;

export const PercentageText = styled.span`
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  line-height: 20.8px;
  background: ${({ theme }) => theme.colors.businessStructureEntityCardPercentageText};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
