import { FC, useState } from "react";
import { Trans } from "react-i18next";
import { ArrowIconSmall, LockIcon } from "assets/svg";
import { Formik, FormikProps } from "formik";

import { COMPANY_CONSUMER_ACCOUNT_AGREEMENT } from "constants/assets";
import { isKycPending, isKycRejected } from "helpers/shared/kyc";

import { Checkbox, FullSectionLoader } from "uikit";

import {
  BackBtn,
  BtnsWrapper,
  BtnsWrapperOuter,
  SubmitBtn,
} from "../../CompanyBankAccountCreationModal/styles";
import { renderErrorMessage, renderKycResponseSection } from "../../components/renderElements";
import {
  CheckboxTextLink,
  CheckboxWrapper,
  FormSection,
  HDescription,
  Header,
  HTitle,
  ScrollableContainer,
} from "../../components/styles";
import {
  KYCProfileForm,
  KYCProfileFormType,
  KYCProfileFormValidationSchema,
} from "./KYCProfileForm";
import { IKYCVerificationProps } from "./types";
import { useKycVerification } from "./useKycVerification";
import { Container, Content, FormContainer } from "./styles";

const KYCVerification: FC<IKYCVerificationProps> = ({
  className,
  currentUser,
  onKybStatusSectionPrimaryBtnClick,
  onBackBtnClick,
  onKybSubmitSuccess,
}) => {
  const translationPrefixOrigin = `components.company_bank_account_components`;
  const translationPrefix = `${translationPrefixOrigin}.kyc_verification_widget`;
  const {
    errorMessage,
    isSubmitting,
    responseType,
    handleSubmitKyc,
    formInitValues,
    kycStatusDetails,
    handleStatusSectionPrimaryBtnClick,
  } = useKycVerification({
    onKybSubmitSuccess,
    onKybStatusSectionPrimaryBtnClick,
  });
  const [isTermsAndConditionsAccepted, setIsTermsAndConditionsAccepted] = useState<boolean>(false);
  const { kycIsAccepted, kycIsPending, kycIsRejected } = kycStatusDetails;
  const [svoc, setSvoc] = useState<boolean>(false);

  const shouldHideBackBtn = kycIsPending;
  const buttonsWrapperClassName = `${shouldHideBackBtn ? "center" : ""}`;
  const shouldShowArrowIcon = !!currentUser?.kycStatus && !isKycRejected && !isKycPending;
  const formSectionClassName = `${isSubmitting ? "loading" : ""}`;
  const kycBtnType = !responseType ? "submit" : "button";

  const shouldDisableSubmitBtn = () => {
    if ((!currentUser?.kycStatus || !responseType) && !isSubmitting)
      return !isTermsAndConditionsAccepted;
    return isSubmitting;
  };

  const getKycStepBtnTitle = () => {
    if (!currentUser?.kycStatus || !responseType) return "buttons.submit";
    if (kycIsAccepted) return "buttons.continue";
    if (kycIsPending) return "buttons.got_it";
    if (kycIsRejected && responseType)
      return `${translationPrefixOrigin}.shared.kyb_kyc_response_section.restart_verification_btn`;
  };

  const onChangeTermsAndConditions = () => {
    setIsTermsAndConditionsAccepted((prev) => !prev);
  };

  const onSubmit = (params: FormikProps<KYCProfileFormType>) => {
    const { values, validateForm } = params;
    if (!responseType) {
      setSvoc(true);
      handleSubmitKyc({ values: values, validateForm: validateForm });
    } else {
      handleStatusSectionPrimaryBtnClick();
    }
  };

  return (
    <Formik<KYCProfileFormType>
      onSubmit={() => {}}
      initialValues={formInitValues}
      validationSchema={KYCProfileFormValidationSchema}
      validateOnChange={svoc}
    >
      {(formikProps) => (
        <Container className={className}>
          <Header>
            <HTitle>
              <Trans i18nKey={`${translationPrefix}.title`} />
            </HTitle>
            <HDescription>
              <Trans i18nKey={`${translationPrefix}.protection_description`} />
              <LockIcon />
            </HDescription>
          </Header>

          {renderErrorMessage(errorMessage)}

          {responseType ? (
            <Content>
              {renderKycResponseSection(
                responseType,
                `${translationPrefixOrigin}.shared.kyb_kyc_response_section`,
              )}
            </Content>
          ) : (
            <FormContainer>
              <ScrollableContainer>
                <FormSection className={formSectionClassName}>
                  {isSubmitting && <FullSectionLoader />}
                  <KYCProfileForm originTranslationPrefix={translationPrefixOrigin} />
                </FormSection>
              </ScrollableContainer>

              <CheckboxWrapper>
                <Checkbox
                  checked={isTermsAndConditionsAccepted}
                  disabled={isSubmitting}
                  onCheck={onChangeTermsAndConditions}
                  onUncheck={onChangeTermsAndConditions}
                  data-testid={"cbacm-kyc-form-terms-and-conditions-checkbox"}
                  text={
                    <Trans
                      i18nKey={`${translationPrefixOrigin}.shared.kyb_kyc_response_section.terms_and_conditions`}
                      components={{
                        1: (
                          <CheckboxTextLink
                            onClick={(e) => e.stopPropagation()}
                            data-testid="terms-and-conditions-link"
                            href={COMPANY_CONSUMER_ACCOUNT_AGREEMENT}
                            target="_blank"
                          />
                        ),
                      }}
                    />
                  }
                ></Checkbox>
              </CheckboxWrapper>
            </FormContainer>
          )}

          <BtnsWrapperOuter>
            <BtnsWrapper className={buttonsWrapperClassName}>
              {!shouldHideBackBtn && (
                <BackBtn
                  onClick={onBackBtnClick}
                  type={"button"}
                  disabled={isSubmitting}
                  id={"cbacm-kyc-form-back-button"}
                  data-testid={"cbacm-kyc-form-back-button"}
                >
                  <Trans i18nKey="buttons.cancel" />
                </BackBtn>
              )}

              <SubmitBtn
                type={kycBtnType}
                disabled={shouldDisableSubmitBtn()}
                onClick={() => onSubmit(formikProps)}
                id={"cbacm-kyc-form-submit-button"}
                data-testid={"cbacm-kyc-form-submit-button"}
              >
                <Trans i18nKey={getKycStepBtnTitle()} />
                {shouldShowArrowIcon && <ArrowIconSmall />}
              </SubmitBtn>
            </BtnsWrapper>
          </BtnsWrapperOuter>
        </Container>
      )}
    </Formik>
  );
};

export default KYCVerification;
