import { FC, useContext, useMemo } from "react";
import { Trans } from "react-i18next";
import { isEmpty } from "lodash";

import { BusinessOwnersStructureContext } from "components/BusinessOwnersStructure";
import { VerificationErrorsSection } from "components/CompanyBankAccountComponents/components";
import {
  renderErrorMessage,
  renderFooterButtonsSection,
  renderKycResponseSection,
} from "components/CompanyBankAccountComponents/components/renderElements";

import { FullSectionLoader } from "uikit";

import { FormSectionContainer, StructureListSection } from "../../components";
import { BusinessStructureEntityType } from "../../components/BusinessStructureEntityCard/types";
import { BusinessOwnersStructureWidgetType, IBusinessOwnersStructureStepProps } from "../../types";
import { useBusinessOwnersStructureStep } from "./useBusinessOwnersStructureStep";
import { Content, ContentWrapper } from "./styles";

const BusinessOwnersStructureStep: FC<IBusinessOwnersStructureStepProps> = ({
  widgetType,
  emphasizedContainer,
  className,
  onPrimaryBtnClick,
  onKybStatusSectionPrimaryBtnClick,
  onBackClick,
}) => {
  const kybResponseStatusTranslationPrefix = `components.company_bank_account_components.shared.kyb_kyc_response_section`;
  const { metadata, pagedData, actions } = useBusinessOwnersStructureStep();
  const {
    kybResponseType,
    setKybResponseType,
    kybErrors,
    setKybErrors,
    isLoading: contextDataIsLoading,
  } = useContext(BusinessOwnersStructureContext);

  const contentWrapperClassName = `${kybResponseType ? kybResponseType : ""}`;
  const contentClassName = `${className} ${kybResponseType ? kybResponseType : ""}`;

  const { businessOwnersStructure } = pagedData;
  const errorMessage = pagedData?.errorMessage;
  const isLoading = metadata.isLoading || contextDataIsLoading;

  const shouldDisableSubmitKybBtn = useMemo(() => {
    const ownersCount = businessOwnersStructure?.owners?.length || 0;
    const businessOwnersCount = businessOwnersStructure?.businessOwners?.length || 0;
    const keyManagersCount = businessOwnersStructure?.keyManagers?.length || 0;
    if ((businessOwnersCount || ownersCount) && keyManagersCount) return false;
    return true;
  }, [businessOwnersStructure?.businessOwners, businessOwnersStructure?.keyManagers]);

  const showCardsList =
    !metadata.selectedEntityType && !metadata.showEntityForm && !kybResponseType;
  const showFormSection =
    !!metadata.selectedEntityType || (!!metadata.showEntityForm && !kybResponseType);

  const handlePrimaryBtnClick = () => {
    if (kybResponseType && onKybStatusSectionPrimaryBtnClick) {
      onKybStatusSectionPrimaryBtnClick(kybResponseType);
      setKybResponseType(null);
      setKybErrors([]);
    } else if (onPrimaryBtnClick) {
      onPrimaryBtnClick();
    }
  };

  return (
    <>
      {isLoading && <FullSectionLoader />}
      {renderErrorMessage(errorMessage)}

      {(showCardsList || kybResponseType) && (
        <>
          <ContentWrapper className={contentWrapperClassName}>
            <Content className={contentClassName} emphasizedContainer={emphasizedContainer}>
              {kybResponseType ? (
                renderKycResponseSection(
                  kybResponseType,
                  kybResponseStatusTranslationPrefix,
                  isLoading,
                )
              ) : (
                <StructureListSection
                  widgetType={widgetType}
                  businessOwnersStructure={businessOwnersStructure || undefined}
                  onSelectEntity={actions.handleSelectEntityClick}
                  onDelete={actions.handleDeleteEntity}
                  onEdit={actions.handleOpenEditEntityForm}
                />
              )}
            </Content>

            {!isEmpty(kybErrors) && (
              <VerificationErrorsSection
                errors={kybErrors}
                businessStructure={businessOwnersStructure || {}}
              />
            )}
          </ContentWrapper>

          {widgetType === BusinessOwnersStructureWidgetType.SETUP && (
            <>
              {renderFooterButtonsSection({
                mainButton: {
                  text: <Trans i18nKey={`buttons.continue`} />,
                  disabled: shouldDisableSubmitKybBtn,
                  onClick: handlePrimaryBtnClick,
                  type: "button",
                  dataTestId: "cbacm-business-structure-submit-button",
                },
                secondaryButton: {
                  text: <Trans i18nKey="buttons.cancel" />,
                  onClick: onBackClick,
                  type: "button",
                  dataTestId: "cbacm-business-structure-form-cancel-button",
                },
              })}
            </>
          )}
        </>
      )}

      {showFormSection && (
        <Content className={className} emphasizedContainer={emphasizedContainer}>
          <FormSectionContainer
            selectedEntityType={metadata.selectedEntityType as BusinessStructureEntityType}
            widgetType={widgetType}
            onBackClick={actions.handleSetupEntityBackClick}
            onSubmitEntity={actions.handleEntityFormSubmit}
            onSubmitEditedEntity={actions.handleEditEntityFormSubmit}
            showEntityForm={metadata.showEntityForm}
            onOpenEntityForm={actions.handleOpenEntityForm}
            entityData={pagedData.entityDataInProcess}
            isFormSubmitting={metadata.isEntityFormSubmitting}
            businessStructure={businessOwnersStructure || {}}
          />
        </Content>
      )}
    </>
  );
};

export default BusinessOwnersStructureStep;
