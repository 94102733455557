import { EMagicLinkFlow } from "types/AuthFlowTypes";

export enum SignUpNestedFormName {
  SignIn = "signIn",
  BusinessDetails = "businessDetails",
  ContactDetails = "contactDetails",
}

export interface ICheckEmailPageLocationState {
  email: string;
  magicLinkFlow: EMagicLinkFlow;
}

export interface IAuthSignInSearchParams {
  code?: string;
  error?: string;
  invitationId?: string;
  impersonateId?: string;
}

export interface IAuthInvitationSearchParams {
  invitationId?: string;
  error?: string;
}
