import styled from "styled-components";

import { EditBtnWithIcon } from "components/BusinessOwnersStructure/components/styles";
import { EKycResponseType } from "components/CompanyBankAccountComponents/employee/KYCVerification/types";
import WarningSection from "components/WarningSection";

import {
  Body1,
  Body2,
  H3,
  NoBorderPrimaryButton,
  PrimaryButton,
  RoundBorderedContainer,
  TextOverflowMixin,
} from "uikit";

import { Content, ContentWrapper } from "../styles";

const gapBetweenSections = "16px";
const gapBetweenRows = "16px";
const gapBetweenCells = "16px";

export const StyledContentWrapper = styled(ContentWrapper)`
  &.${EKycResponseType.ERROR} {
    gap: 32px;
    overflow: auto;
  }
`;

export const StyledContent = styled(Content)`
  &.${EKycResponseType.ERROR} {
    overflow: visible;
  }
`;

export const SectionsContainer = styled.div`
  display: flex;
  gap: ${gapBetweenSections};
  width: 100%;
`;

export const ContentSection = styled(RoundBorderedContainer)`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.contentBg};
  gap: ${gapBetweenRows};
  padding: 16px 24px;
  max-height: 372px;
  overflow: auto;
`;

export const CSTitle = styled(H3)`
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
`;

export const CSRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: ${gapBetweenCells};
  height: 40px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const CSRowInner = styled(CSRow)`
  gap: 0;
  height: auto;
`;

export const CSRowCell = styled.div`
  flex: 1;
  max-width: calc(50% - ${gapBetweenCells});

  &.full-width {
    max-width: 100%;
  }
  &.auto-width {
    flex: none;
    max-width: none;
  }
`;

export const StyledEditButtonWithIcon = styled(EditBtnWithIcon)`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const CSRowCellLabel = styled(Body2)`
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
`;

export const CSRowCellValue = styled(Body2)`
  ${TextOverflowMixin};
  width: 100%;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.colors.textAlt};
`;

export const StyledWarningSection = styled(WarningSection)`
  margin-top: 24px;
`;

export const WarningContentInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
`;

export const WCIText = styled(Body1)`
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.textAlt};

  span {
    margin: 0;
    font-weight: ${({ theme }) => theme.fontWeight.regular};
  }
`;

export const InviteButton = styled(NoBorderPrimaryButton)`
  padding: 0;
  margin: 0;
  width: 180px;
  min-width: 180px;
  max-width: 180px;
  gap: 8px;
  font-size: ${({ theme }) => theme.size.body2};

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const InviteTeamMemberSection = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: end;
  gap: 16px;
`;

export const ITSSelectContainer = styled.div`
  width: 100%;
  max-width: 454px;
`;

export const ITSInviteBtn = styled(PrimaryButton)`
  width: auto;
`;
