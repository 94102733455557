import { OldRoutesForRedirect } from "./oldRoutes";
import routes from "./routes";

interface RedirectRoute {
  oldRoute: OldRoutesForRedirect;
  newRoute: routes;
}

export const redirectRoutes: RedirectRoute[] = [
  {
    oldRoute: OldRoutesForRedirect.EMPLOYEE_BANKING_MONTHLY_STATEMENTS,
    newRoute: routes.BANKING_MONTHLY_STATEMENTS,
  },
  {
    oldRoute: OldRoutesForRedirect.REWARDS,
    newRoute: routes.EMPLOYEE_BANKING_REWARDS,
  },
  {
    oldRoute: OldRoutesForRedirect.EMPLOYEE_CARD_EXTERNAL,
    newRoute: routes.BANKING,
  },
  {
    oldRoute: OldRoutesForRedirect.EMPLOYEE_CARD_DETAILS,
    newRoute: routes.BANKING,
  },
];
