import { FC } from "react";

import IconProps from "./IconProps";

const PhoneIcon: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_14456_8786)">
      <path
        d="M3.33333 2.6665H6L7.33333 5.99984L5.66667 6.99984C6.38064 8.44753 7.55231 9.6192 9 10.3332L10 8.6665L13.3333 9.99984V12.6665C13.3333 13.0201 13.1929 13.3593 12.9428 13.6093C12.6928 13.8594 12.3536 13.9998 12 13.9998C9.39951 13.8418 6.94677 12.7375 5.10455 10.8953C3.26234 9.05307 2.15803 6.60033 2 3.99984C2 3.64622 2.14048 3.30708 2.39052 3.05703C2.64057 2.80698 2.97971 2.6665 3.33333 2.6665Z"
        stroke="#627293"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_14456_8786">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default PhoneIcon;
