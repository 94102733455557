import { useContext } from "react";
import { isEmpty } from "lodash";
import { useAppSelector } from "store/hooks";
import { companyMetadataSelector, userMetadataSelector } from "store/selectors";

import { ErrorConst, KybStatus } from "types/BETypes";
import { getErrorMessageFromResponse } from "helpers/shared/errors";
import { isKybRejected } from "helpers/shared/kyb";
import useAuth from "hooks/useAuth";

import {
  mutationBusinessBankAccountsControllerRunKyb,
  queryBusinessBankAccountsControllerGetKybErrors,
} from "utils/swagger_react_query";

import { BusinessOwnersStructureContext } from "./BusinessOwnersStructureProvider";
import {
  canBusinessOwnerAcceptDisclosure,
  canRerunKyb,
  getAllKeyManagers,
  getKybResponseType,
} from "./helpers";
import { EKybResponseType, IUseKybVerificationParams } from "./types";

export const useKybVerification = ({
  onKybSuccess,
  onKybStatusSectionPrimaryBtnClick,
  isSubmitting,
  setIsSubmitting,
  errorMessage,
  setErrorMessage,
}: IUseKybVerificationParams) => {
  const currentUser = useAppSelector(userMetadataSelector);
  const currentCompany = useAppSelector(companyMetadataSelector);
  const { getCompanyInfo, getCurrentUser } = useAuth();
  const {
    kybResponseType,
    setKybResponseType,
    kybErrors,
    refetchBusinessOwnersStructure,
    setKybErrors,
    businessOwnersStructure,
  } = useContext(BusinessOwnersStructureContext);

  const refetchCurrentUserIfEmailMatch = async (email: string) => {
    const isKeyManager = getAllKeyManagers(businessOwnersStructure).some(
      (keyManager) => keyManager.email === email,
    );
    const isIndividualOwner = businessOwnersStructure?.owners?.some(
      (owner) => owner.email === email,
    );
    if (isKeyManager || isIndividualOwner) {
      return await getCurrentUser();
    }
  };

  const handleKybSubmit = async () => {
    try {
      setKybErrors([]);
      setIsSubmitting(true);
      if (isKybRejected(currentCompany)) {
        const kybErrorsRes = await queryBusinessBankAccountsControllerGetKybErrors();

        const canAcceptDisclosure =
          !isEmpty(businessOwnersStructure?.businessOwners) &&
          canBusinessOwnerAcceptDisclosure(businessOwnersStructure?.businessOwners, kybErrorsRes);
        if (canAcceptDisclosure) {
          setKybErrors(kybErrorsRes);
          setKybResponseType(EKybResponseType.ERROR_BANK_ACCOUNT_CREATED);
          return;
        }
        const canRerun = canRerunKyb(currentCompany, businessOwnersStructure, kybErrorsRes);
        if (!canRerun) {
          setKybErrors(kybErrorsRes);
          setKybResponseType(EKybResponseType.INCORRECT_INFO);
          return;
        }
      }

      const kybResponseStatus = await mutationBusinessBankAccountsControllerRunKyb()();
      const status = getKybResponseType(kybResponseStatus.status as KybStatus);
      if (status === EKybResponseType.ERROR && !isEmpty(kybResponseStatus.errors)) {
        setKybErrors(kybResponseStatus.errors);
      }
      await getCompanyInfo(currentCompany?.companyId || "", true);
      if (!status) throw new Error();

      const canAcceptDisclosure =
        !isEmpty(businessOwnersStructure?.businessOwners) &&
        canBusinessOwnerAcceptDisclosure(
          businessOwnersStructure?.businessOwners,
          kybResponseStatus.errors,
        );
      if (canAcceptDisclosure) {
        setKybResponseType(EKybResponseType.ERROR_BANK_ACCOUNT_CREATED);
      } else {
        setKybResponseType(status);
      }

      refetchCurrentUserIfEmailMatch(currentUser?.email || "");
      onKybSuccess?.();
    } catch (error: any) {
      if (error.data.error === ErrorConst.CANNOT_ACCEPT_DISCLOSURE) {
        throw new Error(error.data.error);
      }
      if (error.data.error === ErrorConst.CANNOT_RUN_KYB_WITHOUT_DISCLOSURE_BUSINESS_OWNER) {
        setKybResponseType(EKybResponseType.ERROR_BANK_ACCOUNT_CREATED);
        return;
      }
      const _error = getErrorMessageFromResponse(error);
      setErrorMessage(_error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleStatusSectionPrimaryBtnClick = () => {
    if (isKybRejected(currentCompany) && kybResponseType) {
      setKybResponseType(null);
      setKybErrors([]);
      setErrorMessage(null);
    }
    onKybStatusSectionPrimaryBtnClick?.(kybResponseType as EKybResponseType);
  };

  return {
    handleKybSubmit,
    handleStatusSectionPrimaryBtnClick,
  };
};
