import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.warning};
  padding: 0;
  box-sizing: border-box;
  z-index: 999;
  text-align: center;
  height: 5px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
`;

export const CenterContainer = styled.div`
  display: inline-block;
  background: ${({ theme }) => theme.colors.warning};
  border-radius: 0 0 8px 8px;
  padding: 5px 20px;
`;

export const Title = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  font-size: ${({ theme }) => theme.size.caption};
  line-height: 24px;
`;

export const LogOutBtn = styled.a`
  cursor: pointer;
  font-size: inherit;
  margin-left: 5px;
  
  &:hover {
    text-decoration: underline;
  }
`;
