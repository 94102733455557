import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { useRouteHelpers } from "routes/helpers";
import routes from "routes/routes";
import reduxActions from "store/actions";
import actions from "store/actions";
import { useAppDispatch } from "store/hooks";

import { getRedirectionLink, saveRedirectionLink } from "services/linkRedirector";
import { UserRole } from "types/BETypes";
import { Pages, permissions as permissionsList } from "permissions";
import { hasEmployeeRights } from "permissions/helpers/shared";
import { showErrorModal } from "helpers";
import {
  getShouldAdminCompleteSignUp,
  getShouldEmployeeCompleteSignUp,
} from "helpers/shared/guardRedirect";
import { useEventsListener } from "hooks";
import useAuth from "hooks/useAuth";
import { useConnectSalsa } from "hooks/useConnectSalsa";
import ActivityTracker from "components/ActivityTracker";
import SalsaTokenRefresher from "components/SalsaTokenRefresher";

import { FullScreenLoader } from "uikit";

import {
  CompanyResponseDto,
  queryUsersControllerGetRewardPoints,
  UserProfileResponseDto,
  UserResponseDto,
} from "utils/swagger_react_query";

import { IRoutePropsExtended } from "../../types";

const ProtectedRoute: FC<IRoutePropsExtended> = (props) => {
  const { children, permissions, disableCondition } = props;

  const [userLoaded, setUserLoaded] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<UserResponseDto | null>(null);
  const [currentCompany, setCurrentCompany] = useState<CompanyResponseDto | null>(null);
  const [userChecked, setUserChecked] = useState<boolean>(false); //Additional flag that determines if we need to redirect user before showing them a page content;
  const { getCurrentUserAndCompany } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [needsDisabling, setNeedsDisabling] = useState<boolean>(false);
  const { connectSalsa } = useConnectSalsa();
  const { getDefaultRoute, getDefaultAdminRoute, getDefaultEmployeeRoute } = useRouteHelpers();
  const dispatch = useAppDispatch();
  //const routerUserRequestInProgress = useAppSelector(routerUserRequestInProgressSelector);

  useEventsListener<"RoleHasUpdated">(
    "RoleHasUpdated",
    async ({ roleHasUpdated, userData }) => {
      setNeedsDisabling(roleHasUpdated);
      if (userData) {
        await connectSalsa(userData, true);
        getAdditionalData(userData, currentCompany);
      }
    },
    [],
  );

  const saveRedirect = () => {
    saveRedirectionLink(location.pathname + location.search);
  };

  const isPermitted = (user?: UserResponseDto | null, company?: CompanyResponseDto | null) => {
    if (needsDisabling) return true;
    if (!user) return false;

    const result =
      !disableCondition?.(user, company) &&
      (!permissions ||
        permissionsList[user?.lastActiveRole as UserRole]?.page[permissions as Pages]);
    return result;
  };

  const checkUserPermission = (
    user?: UserResponseDto | null,
    company?: CompanyResponseDto | null,
  ) => {
    if (!user?.userId || !isPermitted(user, company)) {
      navigate(routes.INSUFFICIENT_PERMISSIONS);
      setUserChecked(true);
      return false;
    } else {
      setUserChecked(true);
      return true;
    }
  };

  const checkRedirects = (
    user?: UserResponseDto | null,
    company?: CompanyResponseDto | null,
    pathname?: string,
  ) => {
    if (!user?.lastActiveRole) {
      navigate(routes.INSUFFICIENT_PERMISSIONS);
      return;
    }

    const redirectionLink = getRedirectionLink(true);

    if (
      redirectionLink &&
      redirectionLink !== getDefaultAdminRoute() &&
      redirectionLink !== getDefaultEmployeeRoute() &&
      redirectionLink !== routes.ROOT
    ) {
      //TODO: Won't work on dev, because of double useEffect execution in React 18, fix later
      //It will redirect once, but second location.pathname will trigger this check again, and won't trigger this "if" anymore
      navigate(redirectionLink);
      return;
    } else if (
      user?.lastActiveRole !== UserRole.EMPLOYEE &&
      ["/", getDefaultEmployeeRoute(user, company)].includes(pathname || "")
    ) {
      navigate(getDefaultRoute(user, company));
      setUserChecked(true);
      return;
    } else if (
      user?.lastActiveRole === UserRole.EMPLOYEE &&
      ["/", getDefaultAdminRoute(user, company)].includes(pathname || "")
    ) {
      navigate(getDefaultRoute(user, company));
      setUserChecked(true);
      return;
    }

    checkUserPermission(user, company);
  };

  const getAdditionalData = async (
    user?: UserProfileResponseDto | null,
    company?: CompanyResponseDto | null,
  ) => {
    if (hasEmployeeRights(user)) {
      try {
        if (user?.isOnboardingComplete) {
          const rewardPoints = await queryUsersControllerGetRewardPoints();
          dispatch(actions.userMetaData.setUserRewardPoints(rewardPoints || null));
        }
      } catch (error) {
        showErrorModal(error);
      }
    }
  };

  async function checkUser() {
    setUserChecked(false);
    const result = await getCurrentUserAndCompany();
    const user = result?.user;
    const company = result?.company;

    if (user && company) {
      const shouldAdminCompleteSignUp = getShouldAdminCompleteSignUp(user);
      const shouldEmployeeCompleteSignUp = getShouldEmployeeCompleteSignUp(user);

      if (shouldAdminCompleteSignUp) {
        saveRedirect();
        return navigate(routes.ADMIN_SIGN_UP);
      }
      if (shouldEmployeeCompleteSignUp) {
        saveRedirect();
        return navigate(routes.EMPLOYEE_SIGN_UP);
      }

      Sentry.setUser({
        id: user.userId,
        email: user.email,
        username: [user.firstName, user.lastName].join(" "),
        companyName: company.name,
        companyId: company.companyId,
        lastActiveRole: user.lastActiveRole,
        roles: user.companyRoles,
      });

      setCurrentUser(user);
      setCurrentCompany(company);
      checkRedirects(user, company, location?.pathname);
      await getAdditionalData(user, company);
      await connectSalsa(user, true);
    } else {
      saveRedirect();
      setUserChecked(true);
    }
    setUserLoaded(true);
  }

  useEffect(() => {
    // dispatch(reduxActions.userMetaData.setRouterUserRequestInProgress(true));
    checkUser();
  }, []);

  //Ducttape for https://keepfinancial.atlassian.net/browse/PAIDSW-813
  //As of now, we don't check route permissions for useNavigate, meaning the page can be accessed using buttons and breadcrumbs
  //The useEffect hook below is a temporary solution. We'll have to refactor our routes to using route loaders, and check all permissions there
  //The reason we stopped using hook on location.pathname change is because the route page is still loaded, before hook takes effect,
  //route loaders are the possible remedy
  useEffect(() => {
    const callback = async () => {
      if (userLoaded && userChecked) {
        const result = await getCurrentUserAndCompany(true); // Getting data again from redux. Otherwise, we might get cached values
        checkUserPermission(result?.user, result?.company);
      }
    };
    callback();
  }, [location.pathname]);

  useEffect(() => {
    if (currentUser && currentCompany) {
      checkRedirects(currentUser, currentCompany, location?.pathname);
    }
  }, [currentUser]);

  useEffect(() => {
    if (userLoaded && userChecked) {
      if (!currentUser) {
        navigate(routes.SIGN_IN);
        return;
      }
      dispatch(reduxActions.userMetaData.setRouterUserRequestInProgress(false));
    }
  }, [userLoaded, userChecked]);

  return (
    <>
      {!userLoaded || !userChecked ? (
        <>
          <FullScreenLoader />
        </>
      ) : (
        <>
          {currentUser && (
            <>
              <ActivityTracker />
              <SalsaTokenRefresher />
              {children}
            </>
          )}
        </>
      )}
    </>
  );
};

export default ProtectedRoute;
