import { FC } from "react";
import { Trans } from "react-i18next";
import { MailIcon, Pencil, PhoneIcon, UserCircleIcon } from "assets/svg";
import { isEmpty } from "lodash";

import { SalsaWorkerCategoryEnum } from "types/BETypes";
import { getFormattedPhoneNumber, getName } from "helpers";
import {
  EComprehensibleUserStatuses,
  getComprehensibleStatus,
} from "helpers/employee/userStatusMap";
import { UserPaymentReadinessStatus, UserStatus } from "components/statuses";
import { WorkerContextMenuActionType } from "components/WorkersWidgets/WorkerActions/types";

import { IContextMenuOption } from "uikit";
import { DangerButton, NoBorderPrimaryButton, PrimaryButton } from "uikit/Buttons";

import { GetEmployeeByIdResponseDto } from "utils/swagger_react_query";

import {
  Container,
  ControlsContainer,
  FullUserNameText,
  LeftSection,
  MainContent,
  MCLeft,
  MCLeftTop,
  MCRight,
  StyledAvatar,
  StyledSecondaryButtonIconed,
  ValueText,
  ValueTextAlt,
} from "./styles";

interface Props {
  className?: string;
  user?: Partial<GetEmployeeByIdResponseDto> | null;
  showControls?: boolean;
  editButton?: IContextMenuOption<WorkerContextMenuActionType.ON_EDIT_WORKER>;
  deleteButton?: IContextMenuOption<WorkerContextMenuActionType.ON_DELETE_WORKER>;
  inviteButton?: IContextMenuOption<WorkerContextMenuActionType.INVITE_WORKER>;
  resendButton?: IContextMenuOption<WorkerContextMenuActionType.RESEND_INVITE>;
  finisSetupButton?: IContextMenuOption<WorkerContextMenuActionType.FINISH_SETUP>;
  onPaymentReadinessStatusClick?: () => void;
}

const EmployeeInfo: FC<Props> = ({
  className,
  user,
  showControls = true,
  editButton,
  deleteButton,
  inviteButton,
  resendButton,
  onPaymentReadinessStatusClick,
}) => {
  const translationPrefix = `components.employee_info`;
  const status = getComprehensibleStatus(user);

  const renderControls = () => {
    const showResendInviteButton =
      !isEmpty(resendButton) && status === EComprehensibleUserStatuses.INVITED;
    const showDeleteButton =
      !isEmpty(deleteButton) && status === EComprehensibleUserStatuses.PROSPECT;
    const showEditButton = !isEmpty(editButton);
    const showInviteButton =
      !isEmpty(inviteButton) && status === EComprehensibleUserStatuses.PROSPECT;

    return (
      <ControlsContainer>
        {showResendInviteButton && (
          <NoBorderPrimaryButton
            onClick={resendButton.onClick}
            disabled={resendButton.disabled}
            data-testid="resend-invite-btn"
          >
            <Trans i18nKey={`${translationPrefix}.resend_invite_btn`} />
          </NoBorderPrimaryButton>
        )}
        {showDeleteButton && (
          <DangerButton
            onClick={deleteButton.onClick}
            disabled={deleteButton.disabled}
            data-testid="delete-btn"
          >
            <Trans i18nKey={`${translationPrefix}.delete_btn`} />
          </DangerButton>
        )}
        {showEditButton && (
          <StyledSecondaryButtonIconed
            onClick={editButton.onClick}
            disabled={editButton.disabled}
            data-testid="edit-btn"
          >
            <Pencil />
            <Trans i18nKey={`${translationPrefix}.edit_btn`} />
          </StyledSecondaryButtonIconed>
        )}

        {showInviteButton && (
          <PrimaryButton
            onClick={inviteButton.onClick}
            disabled={inviteButton.disabled}
            data-testid="invite-btn"
          >
            <Trans i18nKey={`${translationPrefix}.invite_btn`} />
          </PrimaryButton>
        )}
      </ControlsContainer>
    );
  };

  return (
    <Container className={className}>
      <LeftSection>
        <StyledAvatar firstName={user?.firstName} lastName={user?.lastName} src={user?.avatarUrl} />
      </LeftSection>

      <MainContent>
        <MCLeft>
          <MCLeftTop>
            <FullUserNameText>{getName(user)}</FullUserNameText>
            {user && (
              <>
                <UserStatus data={user} />
                <UserPaymentReadinessStatus
                  data={user}
                  withBg
                  onClick={() => onPaymentReadinessStatusClick?.()}
                  data-testid="payment-readiness-status"
                />
              </>
            )}
          </MCLeftTop>
          <ValueTextAlt>{user?.jobTitle || "-"}</ValueTextAlt>

          <ValueText>
            <UserCircleIcon />
            <Trans
              i18nKey={`common.worker_employment_type.${SalsaWorkerCategoryEnum[user?.employmentType as SalsaWorkerCategoryEnum]}`}
            />
          </ValueText>
          <ValueText>
            {" "}
            <MailIcon />
            {user?.email || "-"}
          </ValueText>
          <ValueText>
            <PhoneIcon />
            {getFormattedPhoneNumber(user?.phone || "") || "-"}
          </ValueText>
        </MCLeft>
        {showControls && <MCRight>{renderControls()}</MCRight>}

        {/* <EIARSTopContent>
          <EIABCBox>
            <EIALabelText>
              <Trans i18nKey={`${translationPrefix}.work_email`} />
            </EIALabelText>
            <EIAValueText data-testid="worker-email">{user?.email || "-"}</EIAValueText>
          </EIABCBox>
          <TopRightContainer>{topRightContent}</TopRightContainer>
        </EIARSTopContent>

        <EIARSBottomContent>
          <EIABCBox>
            <EIALabelText>
              <Trans i18nKey={`${translationPrefix}.employment_type`} />
            </EIALabelText>
            <EIAValueText data-testid="worker-employment-type">
              <Trans i18nKey={`common.worker_employment_type.${user?.employmentType}`} />
            </EIAValueText>
          </EIABCBox>

          <EIABCBox>
            <EIALabelText>
              <Trans i18nKey={`${translationPrefix}.phone_number`} />
            </EIALabelText>
            <EIAValueText data-testid="worker-phone-number">{user?.phone || "-"}</EIAValueText>
          </EIABCBox>

          <EIABCBox>
            {showViewDetailsPageLink && (
              <StyledLink to={employeeDetailsPageLink}>
                <Trans i18nKey={`${translationPrefix}.view_link`} />
              </StyledLink>
            )}
            {showEditPageLink && (
              <StyledLink to={employeeEditPageLink}>
                <Trans i18nKey={`${translationPrefix}.edit_link`} />
              </StyledLink>
            )}
            {!!bottomRightContent && bottomRightContent}
          </EIABCBox>
        </EIARSBottomContent> */}
      </MainContent>
    </Container>
  );
};

export default EmployeeInfo;
