import * as yup from "yup";

import {
  addressValidationRulesBase,
  businessInformationValidationRulesAdditional,
  businessInformationValidationRulesBase,
} from "constants/shared/validationRules";

export const businessInformationFormValidationSchema = yup.object({
  ...businessInformationValidationRulesBase,
  ownershipPercentage: businessInformationValidationRulesAdditional.ownershipPercentage,
  address: addressValidationRulesBase.address,
  city: addressValidationRulesBase.city,
  state: addressValidationRulesBase.state,
  zip: addressValidationRulesBase.zip,
  suite: addressValidationRulesBase.suite,
});

export type BusinessInformationFormType = yup.InferType<
  typeof businessInformationFormValidationSchema
>;
