import { FC, useEffect, useState } from "react";
import { Trans } from "react-i18next";
import Select from "react-select";
import { isEmpty } from "lodash";

import { UserRole } from "types/BETypes";
import { getName, getUserRoleName } from "helpers";
import { renderCopyButton } from "components/CompanyBankAccountComponents/components/renderElements";

import { FormLabelSmall, InputContainer } from "uikit";
import {
  Container as SelectContainer,
  ErrorContainer,
  SelectWrapper as SelectContainerInner,
} from "uikit/Input/styles";

import { UserResponseDto } from "utils/swagger_react_query";

import { validateInputValue } from "./helpers";
import { renderAsyncUserSearchInputOptionWithRole } from "./renderElements";
import { IAsyncUserSearchInputProps } from "./types";
import {
  Container,
  OptionCell,
  OptionUserEmailText,
  OptionUserFullNameText,
  OptionUserRoleText,
  SelectedOptionContainer,
  StyledAvatar,
  WarningIcon,
  WarningSection,
  WSText,
} from "./styles";

const AsyncUserSearchInput: FC<IAsyncUserSearchInputProps> = ({
  label,
  searchValue,
  name,
  warning,
  placeholder,
  dataTestid,
  selectedOption,
  className,
  searchResult,
  onSelect,
  isLoading,
  emptyDataMessage,
  onSearch,
  disabled,
  showSelectedOptionBelow,
}) => {
  const translationPrefix = `components.business_owner_structure.entity_form_section`;
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [inputValidationError, setInputValidationError] = useState<string | null>(null);

  const isUserNew = !selectedOption?.userId;
  const shouldValidate = searchValue;
  const showSelectedUserOption = showSelectedOptionBelow && !isUserNew && !isLoading && !warning;
  const showValidationError = inputValidationError && shouldValidate && !isFocused;
  const showWarning = warning && !isLoading;

  useEffect(() => {
    if (isEmpty(searchResult) && !isLoading) {
      handleSetNewUserEmail();
    } else {
      setInputValidationError(null);
      if (!selectedOption?.userId) {
        onSelect(null);
      }
    }
  }, [searchResult, isLoading]);

  const onOptionClick = (data: Partial<UserResponseDto>) => {
    const isUserNew = !data?.userId && !data?.email;
    setIsMenuOpen(false);
    onSelect(data, isUserNew);
  };

  const handleSetError = () => {
    const error = validateInputValue(searchValue);
    if (error) {
      setInputValidationError(error);
      return error;
    } else if (inputValidationError) {
      setInputValidationError(null);
    }
  };

  const handleSetNewUserEmail = () => {
    if (!searchValue) {
      onSelect(null);
      return;
    }

    const error = handleSetError();
    if (!error) {
      onSelect({ email: searchValue }, true);
    } else {
      onSelect(null);
    }
  };

  const handleSearch = (value: string) => {
    onSearch(value);
    setIsMenuOpen(true);
  };

  const onBlur = () => {
    setIsFocused(false);
    setIsMenuOpen(false);
  };
  const onFocus = () => {
    setIsFocused(true);
    setIsMenuOpen(true);
  };

  return (
    <Container className={className}>
      <InputContainer>
        {label && <FormLabelSmall>{label}</FormLabelSmall>}
        <SelectContainer className={`custom-select`}>
          <SelectContainerInner
            isDisabled={!!disabled}
            data-testid={dataTestid || "async-user-search-input"}
            className={`${disabled ? "disabled" : ""}`}
          >
            <Select
              name={name}
              isSearchable={true}
              inputValue={searchValue}
              onInputChange={(val, action) => {
                if (action.action === "input-change") {
                  handleSearch(val);
                }
              }}
              isLoading={isLoading}
              escapeClearsValue={false}
              options={searchResult}
              getOptionValue={(option) => option?.userId || ""}
              getOptionLabel={(option) => option?.email || ""}
              filterOption={() => true}
              placeholder={placeholder}
              isDisabled={disabled}
              menuIsOpen={isMenuOpen}
              onFocus={onFocus}
              onBlur={onBlur}
              closeMenuOnSelect={true}
              openMenuOnFocus={true}
              noOptionsMessage={() => emptyDataMessage}
              components={{
                DropdownIndicator: null,
                Option: (props) =>
                  renderAsyncUserSearchInputOptionWithRole({
                    ...props,
                    onOptionClick,
                    searchValue,
                  }),
              }}
            />
          </SelectContainerInner>
        </SelectContainer>

        {showValidationError && (
          <ErrorContainer data-testid={`${dataTestid || "async-user-search-input"}-error`}>
            <Trans
              i18nKey={inputValidationError}
              values={{
                field: label,
              }}
            />
          </ErrorContainer>
        )}
      </InputContainer>

      {showWarning && (
        <WarningSection>
          <WarningIcon />
          <WSText>
            <Trans
              i18nKey={`${translationPrefix}.search_user_warning.${warning}`}
              components={{
                1: renderCopyButton(),
              }}
            />
          </WSText>
        </WarningSection>
      )}

      {showSelectedUserOption && (
        <SelectedOptionContainer>
          <OptionCell>
            <OptionUserRoleText>
              <StyledAvatar
                firstName={selectedOption?.firstName}
                lastName={selectedOption?.lastName}
              />
            </OptionUserRoleText>
          </OptionCell>

          <OptionCell className="column max_content">
            <OptionUserFullNameText>{getName(selectedOption)}</OptionUserFullNameText>
            <OptionUserEmailText>{selectedOption?.email}</OptionUserEmailText>
          </OptionCell>

          <OptionCell>
            <OptionUserRoleText className={`${selectedOption?.lastActiveRole}`}>
              {getUserRoleName(selectedOption?.lastActiveRole as UserRole)}
            </OptionUserRoleText>
          </OptionCell>
        </SelectedOptionContainer>
      )}
    </Container>
  );
};

export default AsyncUserSearchInput;
