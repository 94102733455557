import { useEffect, useState } from "react";
import { isEmpty } from "lodash";

import { ErrorConst } from "types/BETypes";
import { showErrorModal } from "helpers";
import { getErrorMessageFromResponse } from "helpers/shared/errors";

import {
  GetBusinessStructureResponseDto,
  queryBusinessBankAccountsControllerGetBusinessStructure,
} from "utils/swagger_react_query";

import { IUseFetchBusinessOwnersStructureParams } from "./types";

export const useFetchBusinessOwnersStructure = ({
  fetchDataOnMount,
  isLoadingParent,
  setIsLoadingParent,
  setErrorMessage,
}: IUseFetchBusinessOwnersStructureParams) => {
  const [businessOwnersStructure, setBusinessOwnersStructure] =
    useState<GetBusinessStructureResponseDto | null>(null);
  const [isLoadingInner, setIsLoadingInner] = useState<boolean>(false);

  const isLoading = isLoadingParent || isLoadingInner;
  const setIsLoading = isLoadingParent ? setIsLoadingParent! : setIsLoadingInner;

  useEffect(() => {
    if (fetchDataOnMount && isEmpty(businessOwnersStructure)) {
      fetchData();
    }
  }, []);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await queryBusinessBankAccountsControllerGetBusinessStructure();
      setBusinessOwnersStructure(response);

      return response;
    } catch (error) {
      const _error = getErrorMessageFromResponse(error);
      if (_error === ErrorConst.BUSINESS_NOT_FOUND) return;
      if (setErrorMessage) {
        setErrorMessage(_error);
      } else {
        showErrorModal(_error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return {
    businessOwnersStructure,
    setBusinessOwnersStructure,
    fetchData,
    isLoading,
  };
};
