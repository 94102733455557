import { FC, ReactNode, useMemo } from "react";
import { Trans } from "react-i18next";
import { OptionProps } from "react-select";
import { FilterOptionOption } from "react-select/dist/declarations/src/filters";
import { CheckmarkRoundFilled } from "assets/svg";

import { EBusinessStructureUserStatus } from "types/BETypes";
import { getName } from "helpers";
import { getComprehensibleBusinessStructureUserStatus } from "helpers/employee/userStatusMap";
import {
  renderAsyncUserSearchHighlightedText,
  renderAsyncUserSearchNameWithEmail,
} from "components/AsyncUserSearchInput/renderElements";
import { OptionCell, StyledOption } from "components/AsyncUserSearchInput/styles";
import { BusinessStructureUserStatus } from "components/BusinessOwnersStructure/components";

import { Loader, Select } from "uikit";

import { UserResponseDto } from "utils/swagger_react_query";

import { FHDescription } from "../../styles";
import { Container, ESInviteButton, ESInviteSentState, StyledWarningSection } from "./styles";

type ErrorSectionStatus =
  | EBusinessStructureUserStatus.INVITED
  | EBusinessStructureUserStatus.NOT_INVITED;
type IErrorSectionConfig = Record<
  ErrorSectionStatus,
  {
    title: ReactNode;
    message: ReactNode;
    bottomContent?: ReactNode;
  }
>;

interface IProps {
  potentialCardholdersList: Partial<UserResponseDto>[];
  onChange: (cardholder: Partial<UserResponseDto>) => void;
  selectedCardholderData?: Partial<UserResponseDto>;
  onSentInvite?: (email: string) => void;
  isInviteSent?: boolean;
  isInviteSending?: boolean;
}

const CardholderSelect: FC<IProps> = ({
  potentialCardholdersList,
  onChange,
  selectedCardholderData,
  onSentInvite,
  isInviteSent,
  isInviteSending,
}) => {
  const translationPrefix = `components.company_bank_account_components.card_shipping_step`;

  const customFilterOption = (
    option: FilterOptionOption<Partial<UserResponseDto>>,
    inputValue: string,
  ) => {
    const searchTerm = inputValue.toLowerCase();
    const fullName = `${option.data?.firstName} ${option.data?.lastName}`.toLowerCase();
    const result =
      fullName.includes(searchTerm) || option.data?.email?.toLowerCase().includes(searchTerm);

    return !!result;
  };

  const renderSelectOption = (props: OptionProps<Partial<UserResponseDto>, false>) => {
    const { data, innerRef, innerProps } = props;
    const searchValue = props.selectProps.inputValue;

    return (
      <StyledOption
        ref={innerRef}
        {...innerProps}
        data-testid="async-user-search-result-option"
        onClick={(e) => {
          innerProps.onClick?.(e);
        }}
      >
        {renderAsyncUserSearchNameWithEmail(
          getName(data),
          renderAsyncUserSearchHighlightedText(data?.email, searchValue || ""),
        )}

        <OptionCell>
          <BusinessStructureUserStatus data={data} />
        </OptionCell>
      </StyledOption>
    );
  };

  const renderSentInviteButton = () => {
    const prefix = `${translationPrefix}.shipping_details.cardholder_errors.${[EBusinessStructureUserStatus.NOT_INVITED]}`;
    if (!onSentInvite) return <></>;

    if (isInviteSent) {
      return (
        <ESInviteSentState>
          <CheckmarkRoundFilled />
          <Trans i18nKey={`${prefix}.invitation_sent`} />
        </ESInviteSentState>
      );
    }
    return (
      <ESInviteButton
        className={`${isInviteSending ? "disabled" : ""}`}
        onClick={() => onSentInvite(selectedCardholderData?.email || "")}
        data-testid="send_invite_btn"
      >
        {isInviteSending && <Loader />}
        <Trans i18nKey={`${prefix}.sent_invitation_btn`} />
      </ESInviteButton>
    );
  };

  const renderErrorSection = useMemo(() => {
    if (!selectedCardholderData) return;
    const status = getComprehensibleBusinessStructureUserStatus(selectedCardholderData);
    if (status === EBusinessStructureUserStatus.ACTIVE || !status) return;

    const _prefix = `${translationPrefix}.shipping_details.cardholder_errors.${status}`;
    const config: IErrorSectionConfig = {
      [EBusinessStructureUserStatus.INVITED]: {
        title: <Trans i18nKey={`${_prefix}.title`} />,
        message: <Trans i18nKey={`${_prefix}.message`} />,
      },
      [EBusinessStructureUserStatus.NOT_INVITED]: {
        title: <Trans i18nKey={`${_prefix}.title`} />,
        message: <Trans i18nKey={`${_prefix}.message`} />,
        bottomContent: renderSentInviteButton(),
      },
    };

    return (
      <StyledWarningSection
        title={config[status].title}
        message={config[status].message}
        bottomContent={config[status]?.bottomContent}
      />
    );
  }, [selectedCardholderData, isInviteSent, isInviteSending]);

  return (
    <Container>
      <FHDescription>
        <Trans
          i18nKey={`${translationPrefix}.shipping_details.admin_form_header.user_select.description`}
        />
      </FHDescription>

      <Select
        name={"cardholder"}
        onChange={(e) => onChange(e as unknown as Partial<UserResponseDto>)}
        options={potentialCardholdersList}
        getOptionValue={(option) => option?.userId || ""}
        getOptionLabel={(option) =>
          getName({ firstName: option?.firstName || "", lastName: option?.lastName || "" }) || ""
        }
        filterOption={customFilterOption}
        inputMode="search"
        isSearchable={true}
        components={{
          Option: (props) => renderSelectOption({ ...props } as any),
        }}
      />

      {renderErrorSection}
    </Container>
  );
};

export default CardholderSelect;
