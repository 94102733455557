import { FC, useEffect } from "react";
import { Trans } from "react-i18next";
import { useAppSelector } from "store/hooks";
import { companyMetadataSelector, salsaSelector } from "store/selectors";

import { CancelButton, FrameContainer } from "uikit";

import { BCLeft, BCRight, ButtonsContainer, ContinueButton } from "../styles";
import { Container } from "./styles";

interface Props {
  className?: string;
  onContinue: () => void;
  onBack?: () => void;
  isContinueDisabled: boolean;
  onSalsaOnboardingComplete: () => void;
}

const SetupPayroll: FC<Props> = ({
  className,
  onContinue,
  onBack,
  isContinueDisabled,
  onSalsaOnboardingComplete,
}) => {
  const translationPrefix = `admin_setup_page.steps.setup_payroll`;
  const salsaData = useAppSelector(salsaSelector);
  const currentCompany = useAppSelector(companyMetadataSelector);
  const { salsa, authToken } = salsaData || {};

  const salsaFrameName = "employer-onboarding";
  const salsaFrameId = "onboard-container";
  const frameSelector = `#${salsaFrameId}`;

  useEffect(() => {
    if (currentCompany?.salsaEmployerId && salsaData?.salsa) {
      const salsaElement = salsa.elements.create(salsaFrameName, {
        userToken: authToken,
        employerId: currentCompany?.salsaEmployerId,
        includeSteps: [
          "company-signatory",
          "pay-schedule",
          "payroll-history-declaration",
          "tax-info",
          "work-location",
        ],
      });

      salsa.elements.mount(salsaElement, frameSelector);
      salsaElement.on("complete", () => {
        onSalsaOnboardingComplete();
      });
      return () => {
        salsa.elements.destroy(salsaElement);
      };
    }
  }, [salsaData?.salsa, currentCompany]);

  return (
    <Container className={className}>
      <FrameContainer id={salsaFrameId} />

      <ButtonsContainer>
        <BCLeft>
          {onBack && (
            <CancelButton onClick={onBack} data-testid="setup-payroll-back-button">
              <Trans i18nKey={`${translationPrefix}.back_button`} />
            </CancelButton>
          )}
        </BCLeft>
        <BCRight>
          {!isContinueDisabled && (
            <ContinueButton onClick={onContinue} data-testid="setup-payroll-continue-button">
              <Trans i18nKey={`${translationPrefix}.continue_button`} />
            </ContinueButton>
          )}
        </BCRight>
      </ButtonsContainer>
    </Container>
  );
};

export default SetupPayroll;
