import { FC } from "react";
import { Trans } from "react-i18next";
import { ExclamationRoundedRed } from "assets/svg";
import { isEmpty } from "lodash";

import { getName } from "helpers";
import { getAllKeyManagers } from "components/BusinessOwnersStructure/helpers";

import { GetBusinessStructureResponseDto, KYBErrorResponseDto } from "utils/swagger_react_query";

import { KYBErrorResponseDtoWithPersonName } from "./types";
import {
  VerificationErrorItem,
  VerificationErrorItemText,
  VerificationErrorItemTextBold,
  VerificationErrorList,
  VerificationErrorsContainer,
  VerificationErrorTitle,
} from "./styles";

interface IProps {
  errors: KYBErrorResponseDto[];
  businessStructure: GetBusinessStructureResponseDto;
  className?: string;
}

export const VerificationErrorsSection: FC<IProps> = ({ errors, businessStructure, className }) => {
  const translationPrefix = `components.company_bank_account_components.shared.kyb_kyc_response_section`;

  const mappedErrorsWithNames: KYBErrorResponseDtoWithPersonName[] = errors.map((error) => {
    let name: string = "";
    if (error?.businessStructureBusinessId) {
      if (
        error?.businessStructureBusinessId ===
        businessStructure?.business?.businessStructureBusinessId
      ) {
        name = businessStructure.business?.companyName || "";
      } else {
        name =
          businessStructure.businessOwners?.find(
            (it) => it?.businessStructureBusinessId === error.businessStructureBusinessId,
          )?.companyName || "";
      }
    } else if (error?.businessStructurePersonId) {
      const keyManagers = getAllKeyManagers(businessStructure);
      const keyManager = keyManagers.find(
        (it) => it?.businessStructurePersonId === error.businessStructurePersonId,
      );

      if (!isEmpty(keyManager)) {
        name = getName(keyManager) || "";
      } else {
        const individualOwner = businessStructure.owners?.find(
          (it) => it?.businessStructurePersonId === error.businessStructurePersonId,
        );
        name = getName(individualOwner) || "";
      }
    }
    return { ...error, fullNameOrCompanyName: name };
  });

  if (isEmpty(errors)) return;

  return (
    <VerificationErrorsContainer className={className}>
      <VerificationErrorTitle>
        <Trans i18nKey={`${translationPrefix}.verification_errors_title`} />
      </VerificationErrorTitle>
      <VerificationErrorList>
        {mappedErrorsWithNames?.map((error) => {
          return (
            <VerificationErrorItem key={error.field}>
              <ExclamationRoundedRed />
              <VerificationErrorItemText>
                {error.fullNameOrCompanyName && (
                  <VerificationErrorItemTextBold>
                    {error.fullNameOrCompanyName}
                    {": "}
                  </VerificationErrorItemTextBold>
                )}
                {error.description}
              </VerificationErrorItemText>
            </VerificationErrorItem>
          );
        })}
      </VerificationErrorList>
    </VerificationErrorsContainer>
  );
};
