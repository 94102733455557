import { HELP_EMAIL } from "constants/shared";
import { APPLICATION_NAME } from "constants/systemConstants";

export const adminSetupPageTranslations = {
  "en-US": {
    steps_template: "Step {{index}}: {{name}}",
    steps: {
      setup_payroll: {
        title: "Setup Payroll",
        continue_button: "Continue to Bank Connection",
        back_button: "Back",
      },
      connect_bank: {
        title: "Connect Bank",
        back_button: "Back",
        agreement_text: `<1>Agree to pay using the selected account</1>
          <2>{{companyName}} authorizes the bank account selected above as a direct debit payment method for the collection of fees related to payroll services for ${APPLICATION_NAME} and its partner, Salsa Software Inc. Applicable amounts to be debited from this account, and their due dates will correspond to the payroll that Customer initiates and will thus vary in amount and timing. This authorization will remain in effect until Customer revokes it in writing via ${HELP_EMAIL}</2>`,
        agreement_submit_button: "Submit",
      },
    },
    setup_states: {
      onboarding_complete: {
        title: "Setup Completed",
        message:
          "Congratulations on completing the onboarding! You're all set to pay your workers. Let's get started!",
        primary_button: "Invite Workers",
        secondary_button: "Do It later",
      },
      welcome_message: {
        title: "Let's get Started",
        message:
          "Welcome to Paid! Follow these simple steps to add your workers and start paying them.",
        steps_list: [
          {
            title: "Setup Payroll",
            description:
              "Configure your pay locations, workers pay types, pay schedules and much more to customize pay for your business.",
          },
          {
            title: "Connect your Bank Account",
            description: "Connect your account so we can withdraw the funds and pay your workers.",
          },
        ],
        button: "Continue to Setup",
      },
    },
  },
};

export default adminSetupPageTranslations;
