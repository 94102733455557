import { useCallback, useState } from "react";
import { debounce } from "lodash";

import { getErrorMessageFromResponse } from "helpers/shared/errors";
import {
  convertGetUserIdsByEmailResDtoToUserResponseDto,
  convertUserPersonalInfoResDtoToUserResponseDto,
  convertUserResponseDtoToUserPersonalInfoResDto,
} from "helpers/shared/userData";

import {
  mutationUsersControllerGetUserIdsByEmail,
  queryUsersControllerGetPersonalInfo,
  UserPersonalInfoResDto,
  UserResponseDto,
} from "utils/swagger_react_query";

import { determineUserSearchWarningMessage } from "./helpers";
import { IUseAsyncUserSearchInput } from "./types";

export const useAsyncUserSearchInput = (params: IUseAsyncUserSearchInput) => {
  const { businessStructure, selectedEntityType } = params;
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchResult, setSearchResult] = useState<Partial<UserResponseDto>[]>([]);
  const [selectedUser, setSelectedUser] = useState<Partial<UserResponseDto> | null>(null);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [warningMessage, setWarningMessage] = useState<string | null>(null);

  const handleFetchData = useCallback(
    debounce(async (value: string) => {
      try {
        setIsLoading(true);
        if (!value) {
          setSearchResult([]);

          return;
        }
        const response = await mutationUsersControllerGetUserIdsByEmail()({ email: value || "" });
        const serializedResponse = response.map((item) =>
          convertGetUserIdsByEmailResDtoToUserResponseDto(item),
        );
        setSearchResult(serializedResponse);
      } catch (error) {
        setErrorMessage(getErrorMessageFromResponse(error));
      } finally {
        setIsLoading(false);
      }
    }, 500),
    [],
  );

  const handleSearch = (value: string) => {
    setSearchValue(value);
    setErrorMessage(null);
    setWarningMessage(null);
    handleFetchData(value);
  };

  const handleSelectOption = async (option: Partial<UserResponseDto> | null, newUser?: boolean) => {
    try {
      setIsLoading(true);
      if (!option) {
        setSelectedUser(null);
        return;
      }
      if (newUser) {
        setSelectedUser(option);
        return;
      }

      setSearchValue(option?.email || "");
      let res: UserPersonalInfoResDto | undefined =
        convertUserResponseDtoToUserPersonalInfoResDto(option);
      if (option?.userId) {
        res = await queryUsersControllerGetPersonalInfo({ userId: option?.userId || "" });
      }
      const warning = determineUserSearchWarningMessage(res, businessStructure, selectedEntityType);
      if (warning) {
        setWarningMessage(warning);
        setSelectedUser(null);
        return;
      }
      const serializedRes = convertUserPersonalInfoResDtoToUserResponseDto(res);
      setSelectedUser(serializedRes);
    } catch (error) {
      console.log("error", error);
      setErrorMessage(getErrorMessageFromResponse(error));
    } finally {
      setIsLoading(false);
    }
  };

  const cleanUpStates = () => {
    setSearchValue("");
    setSearchResult([]);
    setSelectedUser(null);
    setErrorMessage(null);
    setWarningMessage(null);
  };

  return {
    metadata: {
      isLoading,
      errorMessage,
      warningMessage,
      cleanUpStates,
    },
    actions: {
      handleSearch,
      handleSelectOption,
    },
    data: {
      searchValue,
      searchResult,
      selectedUser,
    },
  };
};
