import { Trans } from "react-i18next";
import { CopyIcon } from "assets/svg";

import { AppEvents } from "services/events";
import { HELP_EMAIL } from "constants/shared";
import { useFetchBusinessOwnersStructure } from "components/BusinessOwnersStructure";
import { LinkText } from "components/CompanyBankAccountComponents/components/styles";

import { EModalTypes } from "uikit/Modal";

import { StyledCopyText } from "./styles";

export const useBusinessOwnershipStructurePage = () => {
  const translationPrefix = `banking_pages.admin.business_ownership_structure_page.modals`;
  const { businessOwnersStructure, setBusinessOwnersStructure, fetchData, isLoading } =
    useFetchBusinessOwnersStructure({
      fetchDataOnMount: true,
    });

  const handleEditStructure = () => {
    const _translationPrefix = `${translationPrefix}.edit`;
    AppEvents.emit("SetGlobalModal", {
      isOpen: true,
      type: EModalTypes.WARNING,
      title: <Trans i18nKey={`${_translationPrefix}.title`} />,
      message: (
        <Trans
          i18nKey={`${_translationPrefix}.message`}
          components={{
            1: (
              <StyledCopyText id={HELP_EMAIL} copyContent={HELP_EMAIL}>
                <LinkText>{HELP_EMAIL}</LinkText>
                <CopyIcon />
                <Trans i18nKey={"buttons.copy"} />
              </StyledCopyText>
            ),
          }}
        />
      ),
      mainButton: {
        text: <Trans i18nKey={`buttons.done`} />,
      },
    });
  };

  return {
    handleEditStructure,
    isLoading,
    businessOwnersStructure,
    setBusinessOwnersStructure,
    fetchData,
  };
};
