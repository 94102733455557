import moment from "moment";

export const getDaysBetweenDates = (startDate: Date, endDate: Date) => {
  return moment(endDate).diff(moment(startDate), "days");
};

export const getYearsBetweenDates = (
  startDate: Date,
  endDate: Date,
  fractions: boolean = false,
) => {
  return moment(endDate).diff(moment(startDate), "years", fractions);
};

export const getYearsAndDaysBetweenDates = (startDate: Date, endDate: Date) => {
  const fullYears = getYearsBetweenDates(startDate, endDate, false);
  const remainingDays = moment(endDate).diff(moment(startDate).add(fullYears, "years"), "days");
  return {
    years: fullYears,
    days: remainingDays,
  };
};

export const getNoonDate = (date?: Date | string) => {
  return moment(date)
    .utcOffset(0, true)
    .set({ hour: 12, minute: 0, second: 0, millisecond: 0 })
    .toISOString();
};

export const getMinutesBetweenDates = (startDate: Date, endDate: Date) => {
  return moment(endDate).diff(moment(startDate), "minutes");
};
