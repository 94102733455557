import { createContext, FC, useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { useAppSelector } from "store/hooks";
import { companyMetadataSelector } from "store/selectors";

import { isKybRejected } from "helpers/shared/kyb";

import { KYBErrorResponseDto } from "utils/swagger_react_query";

import { canBusinessOwnerAcceptDisclosure } from "./helpers";
import {
  EKybResponseType,
  IBusinessOwnersStructureContext,
  IBusinessOwnersStructureContextProviderProps,
} from "./types";

export const BusinessOwnersStructureContext = createContext<IBusinessOwnersStructureContext>({
  isLoading: false,
  businessOwnersStructure: null,
  refetchBusinessOwnersStructure: async () => undefined,
  setBusinessOwnersStructure: () => {},
  kybResponseType: null,
  setKybResponseType: () => {},
  kybErrors: [],
  setKybErrors: () => {},
  showResponseTypeSectionOnMount: false,
});

const BusinessOwnersStructureContextProvider: FC<IBusinessOwnersStructureContextProviderProps> = ({
  isLoading,
  refetchBusinessOwnersStructure,
  businessOwnersStructure,
  setBusinessOwnersStructure,
  showResponseTypeSectionOnMount,
  children,
}) => {
  const currentCompany = useAppSelector(companyMetadataSelector);
  const [kybResponseType, setKybResponseType] = useState<EKybResponseType | null>(null);
  const [kybErrors, setKybErrors] = useState<KYBErrorResponseDto[]>([]);

  useEffect(() => {
    if (isKybRejected(currentCompany) && showResponseTypeSectionOnMount) {
      const canAcceptDisclosure =
        !isEmpty(businessOwnersStructure?.businessOwners) &&
        canBusinessOwnerAcceptDisclosure(businessOwnersStructure?.businessOwners, kybErrors);

      if (currentCompany?.existsPaidBankAccount) {
        setKybResponseType(EKybResponseType.ERROR_BANK_ACCOUNT_CREATED);
      } else if (canAcceptDisclosure) {
        setKybResponseType(EKybResponseType.ERROR_BANK_ACCOUNT_CREATED);
      } else {
        setKybResponseType(EKybResponseType.ERROR);
      }
    }
  }, [businessOwnersStructure, showResponseTypeSectionOnMount, kybErrors]);

  const providerValue: IBusinessOwnersStructureContext = {
    isLoading,
    refetchBusinessOwnersStructure,
    businessOwnersStructure,
    setBusinessOwnersStructure,
    kybResponseType,
    setKybResponseType,
    kybErrors,
    setKybErrors,
    showResponseTypeSectionOnMount,
  };

  return (
    <BusinessOwnersStructureContext.Provider value={providerValue}>
      {children}
    </BusinessOwnersStructureContext.Provider>
  );
};

export default BusinessOwnersStructureContextProvider;
