import { FC, ReactNode } from "react";
import { Trans } from "react-i18next";

import { getComprehensibleStatus } from "helpers/employee/userStatusMap";

import { UserResponseDto } from "utils/swagger_react_query";

import { Container, Label } from "./styles";

interface IProps {
  data: Partial<UserResponseDto>;
  withBg?: boolean;
  className?: string;
  children?: ReactNode;
}

const UserStatus: FC<IProps> = ({ data, withBg = true, className }) => {
  const transformedStatus = getComprehensibleStatus(data);
  const translationPrefix = "common.user_status";

  return (
    <Container className={className}>
      <Label className={transformedStatus} withBg={!!withBg}>
        <Trans i18nKey={`${translationPrefix}.${transformedStatus}`} />
      </Label>
    </Container>
  );
};

export default UserStatus;
