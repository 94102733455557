import { FC } from "react";

import IconProps from "./IconProps";

const UsersPlusIcon: FC<IconProps> = ({ className }) => (
  <svg
    width="16"
    height="17"
    className={className}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_14070_12587)">
      <path
        d="M3.33203 5.16667C3.33203 5.87391 3.61298 6.55219 4.11308 7.05229C4.61318 7.55238 5.29145 7.83333 5.9987 7.83333C6.70594 7.83333 7.38422 7.55238 7.88432 7.05229C8.38441 6.55219 8.66537 5.87391 8.66537 5.16667C8.66537 4.45942 8.38441 3.78115 7.88432 3.28105C7.38422 2.78095 6.70594 2.5 5.9987 2.5C5.29145 2.5 4.61318 2.78095 4.11308 3.28105C3.61298 3.78115 3.33203 4.45942 3.33203 5.16667Z"
        stroke="#F96331"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2 14.5V13.1667C2 12.4594 2.28095 11.7811 2.78105 11.281C3.28115 10.781 3.95942 10.5 4.66667 10.5H7.33333C7.97333 10.5 8.56 10.7253 9.02 11.1007"
        stroke="#F96331"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.668 2.58643C11.2416 2.73329 11.75 3.06689 12.1131 3.53463C12.4761 4.00237 12.6732 4.57765 12.6732 5.16976C12.6732 5.76187 12.4761 6.33715 12.1131 6.80489C11.75 7.27262 11.2416 7.60623 10.668 7.75309"
        stroke="#F96331"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.668 13.1665H14.668"
        stroke="#F96331"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.668 11.1665V15.1665"
        stroke="#F96331"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_14070_12587">
        <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default UsersPlusIcon;
