import { UserRole } from "types/BETypes";

import {
  BusinessStructureKeyManagerResponseDto,
  BusinessStructureOwnerResponseDto,
  GetUserIdsByEmailResDto,
  UserPersonalInfoResDto,
  UserResponseDto,
} from "utils/swagger_react_query";

export const convertBusinessStructurePersonToUserResponseDto = (
  data: BusinessStructureKeyManagerResponseDto | BusinessStructureOwnerResponseDto,
): Partial<UserResponseDto> => {
  return {
    userId: data?.userId || "",
    firstName: data?.firstName,
    lastName: data?.lastName,
    email: data?.email,
    phone: data?.phoneNumber,
    status: data?.userStatus || undefined,
    companyRoles: data?.userRoles as any,
    socialSecurityNumber: data?.ssn,
    birthDate: data?.dateOfBirth,
    address: data?.address,
    suite: data?.address2,
    city: data?.city || "",
    state: data?.state || null,
    zip: data?.zip || "",
  };
};

export const convertGetUserIdsByEmailResDtoToUserResponseDto = (
  data: GetUserIdsByEmailResDto,
): Partial<UserResponseDto> => {
  return {
    userId: data?.userId || "",
    firstName: data?.firstName || "",
    lastName: data?.lastName || "",
    avatarUrl: (data?.avatarUrl as string) || null,
    email: data?.email || "",
    lastActiveRole: (data?.userRole as UserRole) || undefined,
  };
};

export const convertUserPersonalInfoResDtoToUserResponseDto = (
  data: UserPersonalInfoResDto,
): Partial<UserResponseDto> => {
  return {
    phone: data?.phoneNumber || "",
    socialSecurityNumber: data?.ssn || "",
    birthDate: data?.dateOfBirth || "",
    address: data?.address || "",
    suite: data?.address2 || "",
    city: data?.city || "",
    state: data?.state || null,
    zip: data?.zip || "",
    firstName: data?.firstName || "",
    lastName: data?.lastName || "",
    email: data?.email || "",
    lastActiveRole: (data?.userRole as UserRole) || undefined,
    userId: data?.userId || "",
    avatarUrl: (data?.avatarUrl as string) || null,
  };
};

export const convertUserResponseDtoToUserPersonalInfoResDto = (
  data: Partial<UserResponseDto>,
): UserPersonalInfoResDto => {
  return {
    phoneNumber: data?.phone || "",
    ssn: data?.socialSecurityNumber || "",
    dateOfBirth: data?.birthDate || "",
    address: data?.address || "",
    address2: data?.suite || "",
    city: data?.city || "",
    state: data?.state || undefined,
    zip: data?.zip || "",
    firstName: data?.firstName || "",
    lastName: data?.lastName || "",
    email: data?.email || "",
    userRole: data?.lastActiveRole || undefined,
    businessStructurePersonId: undefined,
    userId: data?.userId || "",
    avatarUrl: data?.avatarUrl || "",
    status: data?.status || undefined,
  };
};
