import { FC, useMemo } from "react";
import { Trans } from "react-i18next";
import { ArrowIcon } from "assets/svg";

import { PaymentReadinessStatus } from "types/user";

import { UserLiteSearchResponseDto } from "utils/swagger_react_query";

import { Container, IconContainer, Label } from "./styles";

interface IProps {
  data: Partial<UserLiteSearchResponseDto>;
  onClick?: () => void;
  withBg?: boolean;
  withIcon?: boolean;
  className?: string;
}

const UserPaymentReadinessStatus: FC<IProps> = ({
  data,
  onClick,
  withBg,
  withIcon = true,
  className,
}) => {
  const translationPrefix = `common.user_readiness_payment_status`;
  const status = PaymentReadinessStatus[data.paymentReadiness as PaymentReadinessStatus];
  const clickable = !!onClick;
  const _className = `${className} ${status}`;

  const icon = useMemo(() => {
    if (!withIcon) return <></>;
    switch (status) {
      case PaymentReadinessStatus.SETUP_PAY:
        return <ArrowIcon />;
      default:
        return;
    }
  }, [status, withIcon]);

  return (
    <Container className={_className} onClick={onClick} data-testid="user-payment-readiness-status">
      <Label className={status} withBg={!!withBg} clickable={clickable}>
        <Trans i18nKey={`${translationPrefix}.${status}`} />
      </Label>
      {icon && <IconContainer className={status}>{icon}</IconContainer>}
    </Container>
  );
};

export default UserPaymentReadinessStatus;
