import { FC } from "react";
import { Trans } from "react-i18next";

import DashboardLayout from "layouts/DashboardLayout";
import {
  BusinessOwnersStructureContextProvider,
  BusinessOwnersStructureStep,
  BusinessOwnersStructureWidgetType,
} from "components/BusinessOwnersStructure";

import {
  PageDescription,
  PageTitle,
  PageTitleLeftContent,
  PageTitleRightContent,
  PageTitleWrapperMargined,
  PrimaryButton,
} from "uikit";

import { useBusinessOwnershipStructurePage } from "./useBusinessOwnershipStructurePage";
import { Container, Header } from "./styles";

const BusinessOwnershipStructurePage: FC = () => {
  const translationPrefix = `banking_pages.admin.business_ownership_structure_page`;
  const {
    handleEditStructure,
    businessOwnersStructure,
    isLoading,
    setBusinessOwnersStructure,
    fetchData,
  } = useBusinessOwnershipStructurePage();

  return (
    <DashboardLayout showTitle={false}>
      <Header>
        <PageTitleWrapperMargined>
          <PageTitleLeftContent>
            <PageTitle>
              <Trans i18nKey={`common.pagesInfo.BANKING_BUSINESS_OWNERSHIP_STRUCTURE.title`} />
            </PageTitle>
            <PageDescription>
              <Trans i18nKey={`${translationPrefix}.description`} />
            </PageDescription>
          </PageTitleLeftContent>

          <PageTitleRightContent>
            <PrimaryButton
              onClick={handleEditStructure}
              data-testid={"business-ownership-structure-edit-button"}
            >
              <Trans i18nKey={`${translationPrefix}.edit_button`} />
            </PrimaryButton>
          </PageTitleRightContent>
        </PageTitleWrapperMargined>
      </Header>

      <Container>
        <BusinessOwnersStructureContextProvider
          isLoading={isLoading}
          businessOwnersStructure={businessOwnersStructure}
          refetchBusinessOwnersStructure={fetchData}
          setBusinessOwnersStructure={setBusinessOwnersStructure}
        >
          <BusinessOwnersStructureStep widgetType={BusinessOwnersStructureWidgetType.VIEW} />
        </BusinessOwnersStructureContextProvider>
      </Container>
    </DashboardLayout>
  );
};

export default BusinessOwnershipStructurePage;
