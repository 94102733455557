import { useState } from "react";
import { useSelector } from "react-redux";
import { userMetadataSelector } from "store/selectors";

import { MFAFactorType, UserRole } from "types/BETypes";
import { hasEmployeeRights } from "permissions/helpers/shared";

import {
  COMPANY_BANK_ACCOUNT_CREATION_ADMIN_STEPS,
  COMPANY_BANK_ACCOUNT_CREATION_EMPLOYEE_STEPS,
} from "../constants";
import { IFormControllerParams } from "../types";

export const useFormController = ({
  factors,
  fetchCompanyBankCards,
  setCurrentStep,
  isShippingStepOnly,
  showSuccessSetupSection,
}: IFormControllerParams) => {
  const currentUser = useSelector(userMetadataSelector);
  const userIsEmployee = hasEmployeeRights(currentUser);

  //SHIPING DETAILS
  const [isShippingDetailsFinished, setIsShippingDetailsFinished] = useState<boolean>(false);
  const [physicalCardSuccessfullyOrdered, setPhysicalCardSuccessfullyOrdered] =
    useState<boolean>(false);
  //MFA
  const [isMfaFinished, setIsMfaFinished] = useState<boolean>(false);
  // KYB
  const [isKybFinished, setIsKybFinished] = useState<boolean>(false);
  // KYC
  const [isKycFinished, setIsKycFinished] = useState<boolean>(false);
  // EARLY PAY
  const [isEarlyPayFinished, setIsEarlyPayFinished] = useState<boolean>(false);
  // BUSINESS INFO
  const [isBusinessInfoFinished, setIsBusinessInfoFinished] = useState<boolean>(false);

  const cleanUpFormsData = () => {
    // SHIPPING DETAILS
    setIsShippingDetailsFinished(false);
    // MFA
    setIsMfaFinished(false);
    // BUSINESS INFO
    setIsBusinessInfoFinished(false);
    // KYB
    setIsKybFinished(false);
    // KYC
    setIsKycFinished(false);
    //EARLY PAY
    setIsEarlyPayFinished(false);
  };

  const handleKybSubmitSuccess = () => {
    setIsKybFinished(true);
  };

  const handleKycSubmitSuccess = () => {
    setIsKycFinished(true);
  };

  const handleEarlyPaySubmitSuccess = () => {
    setIsEarlyPayFinished(true);
    setCurrentStep(COMPANY_BANK_ACCOUNT_CREATION_EMPLOYEE_STEPS.SHIPPING_DETAILS);
  };

  const handleShippingDetailsSubmitSuccess = async () => {
    setIsShippingDetailsFinished(true);
    if (currentUser?.lastActiveRole === UserRole.EMPLOYEE) {
      await fetchCompanyBankCards();
    }
    const shouldShowMfa = !factors?.some(
      (factor) => factor.type === MFAFactorType.SMS && !!factor.isVerified,
    );

    if (shouldShowMfa) {
      if (userIsEmployee) {
        setCurrentStep(COMPANY_BANK_ACCOUNT_CREATION_EMPLOYEE_STEPS.MFA_CONFIGURATION);
      } else {
        setCurrentStep(COMPANY_BANK_ACCOUNT_CREATION_ADMIN_STEPS.MFA_CONFIGURATION);
      }
    } else {
      if (isShippingStepOnly) {
        setPhysicalCardSuccessfullyOrdered(true);
      }
      showSuccessSetupSection();
    }
  };

  const handleSuccessMfaSubmit = () => {
    setIsMfaFinished(true);
  };

  const handleSuccessBusinessInfoSubmit = () => {
    setIsBusinessInfoFinished(true);
    setCurrentStep(COMPANY_BANK_ACCOUNT_CREATION_ADMIN_STEPS.COMPANY_STRUCTURE);
  };

  return {
    cleanUpFormsData,
    cardShippingDetails: {
      cardSuccessfullyOrdered: physicalCardSuccessfullyOrdered,
      isFinished: isShippingDetailsFinished,
      onSubmitSuccess: handleShippingDetailsSubmitSuccess,
    },
    businessInfo: {
      isFinished: isBusinessInfoFinished,
      onSuccessSubmit: handleSuccessBusinessInfoSubmit,
    },
    mfa: {
      setIsMfaFinished,
      isFinished: isMfaFinished,
      onSuccessSubmit: handleSuccessMfaSubmit,
    },
    kyc: {
      isFinished: isKycFinished,
      onSuccessSubmit: handleKycSubmitSuccess,
    },
    kyb: {
      isKybFinished,
      handleKybSubmitSuccess,
    },
    earlyPay: {
      isFinished: isEarlyPayFinished,
      onSuccessSubmit: handleEarlyPaySubmitSuccess,
    },
  };
};
