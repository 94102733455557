import { MouseEventHandler, ReactNode } from "react";
import { Trans } from "react-i18next";
import { CopyIcon } from "assets/svg";

import { HELP_EMAIL } from "constants/shared";
import { getTranslatedErrorMessageFromResponse } from "helpers/shared/errors";
import { EKybResponseType } from "components/BusinessOwnersStructure/types";

import { EModalTypes } from "uikit/Modal";
import ModalStatusIcon from "uikit/Modal/components/ModalStatus";

import {
  BackBtn,
  BtnsWrapper,
  BtnsWrapperOuter,
  SubmitBtn,
} from "../CompanyBankAccountCreationModal/styles";
import { EKycResponseType } from "../employee/KYCVerification/types";
import {
  EMIcon,
  EMText,
  ErrorMessageBox,
  LinkText,
  ResponseStatusContainer,
  ResponseStatusMessage,
  ResponseStatusTitle,
  StyledCopyText,
} from "./styles";

type IRenderKycResponseSectionIcon<T> = Record<
  T extends EKybResponseType ? EKybResponseType : EKycResponseType,
  EModalTypes
>;

export const renderErrorMessage = (
  errorMessage?: string | undefined | null,
  values?: Record<string, string>,
) => {
  if (!errorMessage) return;

  return (
    <ErrorMessageBox>
      <EMIcon />
      <EMText>{getTranslatedErrorMessageFromResponse(errorMessage, values)}</EMText>
    </ErrorMessageBox>
  );
};

export const renderCopyButton = () => (
  <StyledCopyText id={HELP_EMAIL} copyContent={HELP_EMAIL}>
    <LinkText>{HELP_EMAIL}</LinkText>
    <CopyIcon />
    <Trans i18nKey={"buttons.copy"} />
  </StyledCopyText>
);

export const renderKycResponseSection = (
  responseType: EKycResponseType | EKybResponseType,
  translationPrefix: string,
  isLoading?: boolean,
) => {
  if (!responseType) return;
  const isKybResponseType = Object.values(EKybResponseType).includes(
    responseType as EKybResponseType,
  );
  const _prefix = `${translationPrefix}.verification_statuses.${responseType}`;
  const kybIconType: IRenderKycResponseSectionIcon<EKybResponseType> = {
    [EKybResponseType.SUCCESS]: EModalTypes.SUCCESS,
    [EKybResponseType.ERROR]: EModalTypes.ERROR,
    [EKybResponseType.VERIFICATION_IS_TAKING_TOO_LONG]: EModalTypes.PENDING,
    [EKybResponseType.INCORRECT_INFO]: EModalTypes.USER,
    [EKybResponseType.ERROR_BANK_ACCOUNT_CREATED]: EModalTypes.ERROR,
  };
  const kycIconType: IRenderKycResponseSectionIcon<EKycResponseType> = {
    [EKycResponseType.SUCCESS]: EModalTypes.SUCCESS,
    [EKycResponseType.ERROR]: EModalTypes.ERROR,
    [EKycResponseType.VERIFICATION_IS_TAKING_TOO_LONG]: EModalTypes.PENDING,
    [EKycResponseType.INCORRECT_INFO]: EModalTypes.USER,
  };

  return renderResponseSection({
    title: <Trans i18nKey={`${_prefix}.title`} />,
    message: (
      <Trans
        i18nKey={`${_prefix}.message`}
        components={{
          1: renderCopyButton(),
        }}
      />
    ),
    iconType: isKybResponseType
      ? kybIconType[responseType as EKybResponseType]
      : kycIconType[responseType as EKycResponseType],
    dataTestId: `kyc-verification-response-${responseType}`,
    isLoading,
  });
};

interface IRenderResponseSection {
  title: string | ReactNode;
  message?: string | ReactNode;
  iconType: EModalTypes;
  dataTestId?: string;
  isLoading?: boolean;
}

export const renderResponseSection = ({
  title,
  message,
  iconType,
  dataTestId,
  isLoading,
}: IRenderResponseSection) => {
  return (
    <ResponseStatusContainer data-testid={dataTestId || ""}>
      <ModalStatusIcon type={iconType} />
      {!isLoading && (
        <>
          <ResponseStatusTitle>{title}</ResponseStatusTitle>
          {message && <ResponseStatusMessage>{message}</ResponseStatusMessage>}
        </>
      )}
    </ResponseStatusContainer>
  );
};

interface IRenderFooterButtonsSection {
  containerClassName?: string;
  mainButton: {
    text: ReactNode;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    disabled?: boolean;
    type?: "button" | "submit";
    dataTestId?: string;
    className?: string;
  };
  secondaryButton?: {
    text: ReactNode;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    disabled?: boolean;
    type?: "button" | "submit";
    dataTestId?: string;
    className?: string;
  };
}

export const renderFooterButtonsSection = ({
  mainButton,
  secondaryButton,
}: IRenderFooterButtonsSection) => {
  return (
    <BtnsWrapperOuter>
      <BtnsWrapper>
        {secondaryButton && (
          <BackBtn
            type={secondaryButton?.type || "button"}
            id={secondaryButton?.dataTestId || ""}
            data-testid={secondaryButton?.dataTestId || ""}
            disabled={secondaryButton?.disabled}
            onClick={secondaryButton?.onClick}
            className={secondaryButton?.className || ""}
          >
            {secondaryButton?.text}
          </BackBtn>
        )}

        {mainButton && (
          <SubmitBtn
            type={mainButton.type || "button"}
            id={mainButton.dataTestId || ""}
            data-testid={mainButton.dataTestId || ""}
            disabled={mainButton.disabled}
            onClick={mainButton.onClick}
            className={mainButton?.className || ""}
          >
            {mainButton.text}
          </SubmitBtn>
        )}
      </BtnsWrapper>
    </BtnsWrapperOuter>
  );
};
