import { FC, useState } from "react";
import { Trans } from "react-i18next";
import { isEmpty } from "lodash";

import { DefaultBankAccountType } from "types/BETypes";
import { checkSetupCompletion } from "helpers/setup/sharedSetup";
import {
  ExternalBankAccountsSection,
  PaidBankAccountSection,
} from "components/BankAccountComponents";
import CompanyBankAccountCreationModal from "components/CompanyBankAccountComponents/CompanyBankAccountCreationModal";
import { EWidgetType } from "components/PaymentMethods/types";

import { Loader } from "uikit";

import { ADMIN_SETUP_HIGHLIGHTED_AGREEMENT_SECTION_CLASS_NAME } from "../../constants";
import { BCLeft, BCRight, ButtonsContainer, CancelButton } from "../styles";
import { useConnectBankStep } from "./useConnectBankStep";
import {
  AgreementBlock,
  AgreementBlockMessage,
  AgreementBlockTitle,
  AgreementCheckbox,
  Container,
  SubmitButton,
} from "./styles";

interface Props {
  className?: string;
  onBack: () => void;
  onAgreementSubmit: () => void;
}

const ConnectBank: FC<Props> = ({ className, onBack, onAgreementSubmit }) => {
  const translationPrefix = `admin_setup_page.steps.connect_bank`;
  // const defaultAccount = !!bankAccountSectionProps?.defaultBankAccountId;
  const { metadata, pageData, actions, modals } = useConnectBankStep();
  const { currentUser, currentCompany, isLoading } = metadata;
  const showAgreementBlock = !!pageData.defaultBankAccountId;
  const [isAgreementChecked, setAgreementChecked] = useState<boolean>(false);
  const [shouldHighlighAgreementSection, setShouldHighlighAgreementSection] =
    useState<boolean>(false);
  const agreementSectionClassName = shouldHighlighAgreementSection
    ? ADMIN_SETUP_HIGHLIGHTED_AGREEMENT_SECTION_CLASS_NAME
    : "";

  const onAgreementCheckboxChange = (checked: boolean) => {
    if (checked) {
      setAgreementChecked(checked);
      setShouldHighlighAgreementSection(false);
    } else {
      setAgreementChecked(checked);
    }
  };

  const handleAgreementSubmit = () => {
    if (!isAgreementChecked) {
      setShouldHighlighAgreementSection(true);
    } else {
      setShouldHighlighAgreementSection(false);
      onAgreementSubmit();
    }
  };

  const setupHasCompleted = checkSetupCompletion({
    currentUser: metadata.currentUser,
    currentCompany: metadata.currentCompany,
    internalBankCards: pageData.internalBankAccount.bankCards,
    factors: pageData.factorsData || [],
  });

  return (
    <Container className={className}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <PaidBankAccountSection
            bankAccounts={
              !isEmpty(pageData.internalBankAccount.companyBankAccount)
                ? [pageData.internalBankAccount.companyBankAccount]
                : []
            }
            onBannerBtnClick={actions.handleOpenSetupModal}
            widgetType={EWidgetType.ONBOARDING}
            setupCompleted={setupHasCompleted}
            onContinueSetupClick={actions.handleOpenSetupModal}
            defaultBankAccountId={pageData.defaultBankAccountId || ""}
            onChangeDefaultBank={(id) =>
              actions.handleSetDefaultBankAccount(id, DefaultBankAccountType.PARTNER_ACCOUNT)
            }
          />

          <ExternalBankAccountsSection
            bankAccounts={pageData.externalBankAccounts || []}
            onConnectBankAccount={actions.externalBankAccounts.openModal}
            onRemoveBankAccount={actions.externalBankAccounts.handleRemoveBankAccount}
            onVerifyBankAccount={actions.externalBankAccounts.handleVerifyBankAccount}
            defaultBankAccountId={pageData.defaultBankAccountId || ""}
            onChangeDefaultBank={(id) =>
              actions.handleSetDefaultBankAccount(id, DefaultBankAccountType.EXTERNAL_ACCOUNT)
            }
            showConnectBankAccountButtonBelowList={!!pageData.externalBankAccounts?.length}
          />

          {showAgreementBlock && (
            <AgreementBlock className={agreementSectionClassName}>
              <AgreementCheckbox
                checked={isAgreementChecked}
                onCheck={() => {
                  onAgreementCheckboxChange(true);
                }}
                onUncheck={() => {
                  onAgreementCheckboxChange(false);
                }}
                text={
                  <Trans
                    i18nKey={`${translationPrefix}.agreement_text`}
                    components={{
                      1: <AgreementBlockTitle className={agreementSectionClassName} />,
                      2: <AgreementBlockMessage />,
                    }}
                    values={{
                      companyName: currentCompany?.name || "",
                    }}
                  />
                }
              />
            </AgreementBlock>
          )}

          <ButtonsContainer>
            <BCLeft>
              <CancelButton onClick={onBack} data-testid="back_button">
                <Trans i18nKey={`${translationPrefix}.back_button`} />
              </CancelButton>
            </BCLeft>

            {showAgreementBlock && (
              <BCRight>
                <SubmitButton onClick={handleAgreementSubmit} data-testid="agreement_submit_button">
                  <Trans i18nKey={`${translationPrefix}.agreement_submit_button`} />
                </SubmitButton>
              </BCRight>
            )}
          </ButtonsContainer>
        </>
      )}

      <CompanyBankAccountCreationModal
        isOpen={modals.isCreationBusinessBankAccountModalOpen}
        setIsOpen={modals.setIsCreationBusinessBankAccountModalOpen}
        onFinished={actions.initFetch}
        refetchPageContentCallback={actions.initFetch}
      />
    </Container>
  );
};

export default ConnectBank;
