import styled from "styled-components";

import { NoBorderPrimaryButtonMixin } from "uikit/Buttons/Buttons";
import { SecondaryActionButton } from "uikit/Modal/styles";

export const TabsContainer = styled.div``;

export const DefaultFinishedContentWrapper = styled.div`
  ${SecondaryActionButton} {
    ${NoBorderPrimaryButtonMixin};
  }
`;
