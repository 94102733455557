import { FC, ReactNode } from "react";

import { Container, Content, IconContainer, Message, Title, WarningIcon } from "./styles";

interface IProps {
  showIcon?: boolean;
  title?: ReactNode;
  message?: ReactNode;
  bottomContent?: ReactNode;
  children?: ReactNode;
  className?: string;
}

const WarningSection: FC<IProps> = ({
  showIcon = true,
  title,
  message,
  bottomContent,
  children,
  className,
}) => {
  return (
    <Container className={className}>
      {showIcon && (
        <IconContainer>
          <WarningIcon />
        </IconContainer>
      )}
      <Content>
        {children ? (
          children
        ) : (
          <>
            {title && <Title>{title}</Title>}
            {message && <Message>{message}</Message>}
            {bottomContent && bottomContent}
          </>
        )}
      </Content>
    </Container>
  );
};

export default WarningSection;
