import { useEffect } from "react";
import { Trans } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import routes from "routes/routes";

import { AppEvents } from "services/events";
import { EMagicLinkFlow } from "types/AuthFlowTypes";
import { signInErrors } from "constants/auth";

import { EModalTypes } from "uikit/Modal";

import { useCompleteSignIn, useSingIn } from "../_shared";

interface IParams {
  errorModalsTranslationsPrefix: string;
}

export const useSignInPage = ({ errorModalsTranslationsPrefix }: IParams) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const accountIsDisabled = searchParams.has(signInErrors.ACCOUNT_IS_DISABLED);
  const sessionHasExpired = searchParams.has(signInErrors.SESSION_HAS_EXPIRED);

  const useSignIn = useSingIn(EMagicLinkFlow.SIGN_IN);

  const {
    metadata: { isLoading: pageLoader },
  } = useCompleteSignIn({ mode: EMagicLinkFlow.SIGN_IN });

  useEffect(() => {
    if (accountIsDisabled) {
      showAccountDisabledModal();
    }
    if (sessionHasExpired) {
      showSessionExpiredModal();
    }
  }, [accountIsDisabled, sessionHasExpired]);

  const showAccountDisabledModal = () => {
    if (accountIsDisabled) {
      AppEvents.emit("SetGlobalModal", {
        isOpen: true,
        title: <Trans i18nKey={`${errorModalsTranslationsPrefix}.account_was_disabled.title`} />,
        onClose: () => navigate(routes.SIGN_IN),
        mainButton: {
          text: <Trans i18nKey="common.modal.okay" />,
          onClick: () => AppEvents.emit("SetGlobalModal", { isOpen: false }),
        },
      });
    }
  };

  const showSessionExpiredModal = () => {
    if (sessionHasExpired) {
      AppEvents.emit("SetGlobalModal", {
        isOpen: true,
        type: EModalTypes.WARNING,
        title: <Trans i18nKey={`${errorModalsTranslationsPrefix}.session_has_expired.title`} />,
        message: <Trans i18nKey={`${errorModalsTranslationsPrefix}.session_has_expired.message`} />,
        mainButton: {
          text: <Trans i18nKey="common.modal.okay" />,
          onClick: () => {
            navigate(routes.SIGN_IN);
          },
        },
        onClose: () => {
          navigate(routes.SIGN_IN);
        },
      });
    }
  };

  return {
    ...useSignIn,
    pageLoader,
  };
};
