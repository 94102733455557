import styled from "styled-components";

import { CloseModalButton, SlidingPane } from "uikit";
import { NoBorderPrimaryButtonMixin } from "uikit/Buttons/Buttons";
import { MainActionButton, SecondaryActionButton } from "uikit/Modal/styles";

export const AddWorkerPane = styled(SlidingPane)`
  width: 680px !important;
`;

export const AddWorkerCloseButton = styled(CloseModalButton)`
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
`;

export const WorkerAddedModalInner = styled.div`
  ${SecondaryActionButton} {
    ${NoBorderPrimaryButtonMixin};
    margin: 0;
    min-width: auto;
    width: auto;
  }

  ${MainActionButton} {
    width: auto;
  }
`;
