import styled from "styled-components";

import { Body2, H3, RoundBorderedContainer } from "uikit";
import { EmphasizedContentBadge } from "uikit/Containers/Containers";

import { iconBtnMixin } from "../styles";
import { BusinessStructureEntityCardState } from "./types";

const emptyStateHeight = 98;

export const Container = styled(RoundBorderedContainer)<{
  state: BusinessStructureEntityCardState;
}>`
  margin-bottom: 24px;
  display: flex;
  background-color: ${({ theme }) => theme.colors.contentBg};

  ${({ state }) =>
    state === BusinessStructureEntityCardState.FILLED &&
    `
    flex-direction: column;
    padding: 16px 24px;
    
  `}

  ${({ state, theme }) =>
    state === BusinessStructureEntityCardState.EMPTY &&
    `
    height: ${emptyStateHeight}px;
    padding: 24px;
    gap: 12px;
    transition: background-color 0.2s;

    &:hover {
      cursor: pointer;
      background-color: ${theme.colors.businessStructureEntityCardBgHover}08;
    }
  `}
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.large {
    flex: 1;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  &:nth-child(1) {
    margin-bottom: 8px;
  }
`;

export const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  align-self: center;

  svg.user {
    path {
      stroke: ${({ theme }) => theme.colors.accentMain};
    }
  }
`;

export const Title = styled(H3)`
  margin-bottom: 4px;
`;

export const Description = styled(Body2)`
  color: ${({ theme }) => theme.colors.textAlt};

  span {
    margin-left: 8px;
  }
`;

export const ActionButtonsContainer = styled.div`
  gap: 8px;
  display: flex;
`;

export const DeleteBtn = styled.span`
  ${iconBtnMixin};
  svg {
    path {
      stroke: ${({ theme }) => theme.colors.danger};
    }
  }
`;

export const BusinessKeyManagerBadge = styled(EmphasizedContentBadge)`
  svg {
    margin-right: 8px;
    width: 16px;
    height: 16px;

    path {
      stroke: ${({ theme }) => theme.colors.textAlt4};
    }
  }
`;
