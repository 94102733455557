import {
  BusinessStructureBusinessOwnersResponseDto,
  BusinessStructureKeyManagerResponseDto,
  BusinessStructureOwnerResponseDto,
  GetBusinessStructureResponseDto,
  KYBErrorResponseDto,
} from "utils/swagger_react_query";

export enum EKybResponseType {
  SUCCESS = "success",
  VERIFICATION_IS_TAKING_TOO_LONG = "verification_is_taking_too_long",
  ERROR = "error",
  ERROR_BANK_ACCOUNT_CREATED = "error_bank_account_created",
  INCORRECT_INFO = "incorrect_info",
}

export enum BusinessOwnersStructureWidgetType {
  SETUP = "SETUP",
  VIEW = "VIEW",
}

export type BusinessStructureEntityDataType =
  | BusinessStructureKeyManagerResponseDto
  | BusinessStructureOwnerResponseDto
  | BusinessStructureBusinessOwnersResponseDto;

export type BusinessStructurePersonEntityDataType =
  | BusinessStructureOwnerResponseDto
  | BusinessStructureKeyManagerResponseDto;

export enum IBusinessOwnersStructureDisclosuresType {
  CERTIFICATION = "CERTIFICATION",
  TERMS_AND_CONDITIONS = "TERMS_AND_CONDITIONS",
}

export interface IUseFetchBusinessOwnersStructureParams {
  fetchDataOnMount: boolean;
  isLoadingParent?: boolean;
  setIsLoadingParent?: (isLoading: boolean) => void;
  setErrorMessage?: (errorMessage: string | null) => void;
}

export interface IBusinessOwnersStructureStepProps {
  widgetType: BusinessOwnersStructureWidgetType;
  emphasizedContainer?: boolean;
  className?: string;
  onPrimaryBtnClick?: () => void;
  onKybStatusSectionPrimaryBtnClick?: (status: EKybResponseType) => void;
  onBackClick?: () => void;
}

export type IUseBusinessOwnersStructureStepParams = {};

export interface IReviewCertifyStep {
  onKybStatusSectionPrimaryBtnClick?: (status: EKybResponseType) => void;
  onKybSuccess?: () => void;
  onBackClick: () => void;
  onBusinessInfoEditClick: () => void;
  onBusinessOwnersEditClick: () => void;
}

export type IUseReviewCertifyStepParams = Pick<
  IReviewCertifyStep,
  "onKybSuccess" | "onKybStatusSectionPrimaryBtnClick"
>;

export interface IUseKybVerificationParams
  extends Pick<IUseReviewCertifyStepParams, "onKybSuccess" | "onKybStatusSectionPrimaryBtnClick"> {
  setIsSubmitting: (isSubmitting: boolean) => void;
  isSubmitting: boolean;
  errorMessage: string | null;
  setErrorMessage: (errorMessage: string | null) => void;
}

export interface IBusinessOwnersStructureContextProviderProps
  extends Pick<
    IBusinessOwnersStructureContext,
    | "refetchBusinessOwnersStructure"
    | "businessOwnersStructure"
    | "setBusinessOwnersStructure"
    | "showResponseTypeSectionOnMount"
    | "isLoading"
  > {
  children: React.ReactNode;
}
export interface IBusinessOwnersStructureContext {
  isLoading: boolean;
  refetchBusinessOwnersStructure: () => Promise<GetBusinessStructureResponseDto | undefined>;
  businessOwnersStructure: GetBusinessStructureResponseDto | null;
  setBusinessOwnersStructure: (data: GetBusinessStructureResponseDto | null) => void;
  kybResponseType: EKybResponseType | null;
  setKybResponseType: (responseType: EKybResponseType | null) => void;
  kybErrors: KYBErrorResponseDto[];
  setKybErrors: (kybErrors: KYBErrorResponseDto[]) => void;
  showResponseTypeSectionOnMount?: boolean;
}
