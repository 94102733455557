import styled, { css } from "styled-components";

import { breakpoint } from "helpers";
import { EKycResponseType } from "components/CompanyBankAccountComponents/employee/KYCVerification/types";

import { sectionAltRoundedMixin } from "uikit/Containers/Containers";

const emphasizedContainerMixin = css`
  ${sectionAltRoundedMixin}
`;

export const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  &.${EKycResponseType.ERROR} {
    gap: 32px;
    overflow: auto;
  }
`;

export const Content = styled.div<{ emphasizedContainer?: boolean }>`
  ${({ emphasizedContainer }) => emphasizedContainer && emphasizedContainerMixin};
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin-bottom: 0;

  &.${EKycResponseType.ERROR} {
    overflow: visible;
  }

  ${breakpoint("xs", "xlm")`
    margin-bottom: 0;
  `}
`;
