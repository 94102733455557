import { Trans } from "react-i18next";

import { SalsaWorkerPaymentReadinessEnum } from "types/BETypes";
import {
  EComprehensibleUserStatuses,
  getComprehensibleStatus,
} from "helpers/employee/userStatusMap";

import { IContextMenuOption } from "uikit";

import { UserResponseDto } from "utils/swagger_react_query";

import { WorkerContextMenuActionType } from "./types";

export type IMenuAction = (data: Partial<UserResponseDto>, callback?: () => void) => void;

export interface IEmployeesListContextMenuOptionsActions {
  onFinishSetup: IMenuAction;
  onInviteWorker: IMenuAction;
  onResendInvite: IMenuAction;
  onEditWorker: IMenuAction;
  onDeleteWorker: IMenuAction;
}

const useContextMenu = ({
  onFinishSetup,
  onInviteWorker,
  onResendInvite,
  onEditWorker,
  onDeleteWorker,
}: IEmployeesListContextMenuOptionsActions) => {
  const translationPrefix = `people_page.index.table.menu_actions`;

  const getContextMenuOptions = (
    data: Partial<UserResponseDto>,
  ): Array<IContextMenuOption<WorkerContextMenuActionType> | undefined> => {
    const status = getComprehensibleStatus(data);

    const menuActions = {
      FINISH_SETUP: {
        label: <Trans i18nKey={`${translationPrefix}.finish_setup`} />,
        value: WorkerContextMenuActionType.FINISH_SETUP,
        onClick: () => onFinishSetup(data),
      },
      INVITE_WORKER: {
        label: <Trans i18nKey={`${translationPrefix}.invite_worker`} />,
        value: WorkerContextMenuActionType.INVITE_WORKER,
        onClick: () => onInviteWorker(data),
      },
      RESEND_INVITE: {
        label: <Trans i18nKey={`${translationPrefix}.resend_invite`} />,
        value: WorkerContextMenuActionType.RESEND_INVITE,
        onClick: () => onResendInvite(data),
      },
      ON_EDIT_WORKER: {
        label: <Trans i18nKey={`${translationPrefix}.edit_worker`} />,
        value: WorkerContextMenuActionType.ON_EDIT_WORKER,
        onClick: () => onEditWorker(data),
      },
      ON_DELETE_WORKER: {
        label: <Trans i18nKey={`${translationPrefix}.delete_worker`} />,
        value: WorkerContextMenuActionType.ON_DELETE_WORKER,
        onClick: () => onDeleteWorker(data),
      },
    };

    const statusActions = {
      [EComprehensibleUserStatuses.EMPLOYED]: [menuActions.ON_EDIT_WORKER],
      [EComprehensibleUserStatuses.INVITED]: [
        menuActions.ON_EDIT_WORKER,
        menuActions.RESEND_INVITE,
        menuActions.FINISH_SETUP,
      ],
      [EComprehensibleUserStatuses.PROSPECT]: [
        menuActions.INVITE_WORKER,
        menuActions.FINISH_SETUP,
        menuActions.ON_EDIT_WORKER,
        menuActions.ON_DELETE_WORKER,
      ],

      //TODO: Remove these statuses later, if they are deemed unnecessary
      [EComprehensibleUserStatuses.PENDING_EXIT]: [],
      [EComprehensibleUserStatuses.EXITED]: [],
      [EComprehensibleUserStatuses.IN_DEFAULT]: [],
      [EComprehensibleUserStatuses.NOT_READY]: [menuActions.ON_EDIT_WORKER],
    };

    let result = status ? statusActions[status] : [];

    if (status === EComprehensibleUserStatuses.EMPLOYED) {
      if (data.salsaPaymentReadiness === SalsaWorkerPaymentReadinessEnum.ONBOARDING) {
        result.push(menuActions.FINISH_SETUP);
      }
      if (
        !data.salsaPaymentReadiness ||
        data.salsaPaymentReadiness === SalsaWorkerPaymentReadinessEnum.READY
      ) {
        result = [menuActions.ON_EDIT_WORKER];
      }
    }

    return result;
  };

  return {
    getContextMenuOptions,
  };
};

export default useContextMenu;
