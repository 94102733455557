import styled from "styled-components";

import { Body2, Body3, Checkbox, PrimaryButton } from "uikit";
import { Checkmark } from "uikit/Checkbox/styles";

import { ADMIN_SETUP_HIGHLIGHTED_AGREEMENT_SECTION_CLASS_NAME } from "../../constants";

export const Container = styled.div``;

export const SubmitButton = styled(PrimaryButton)`
  min-width: 177px;
`;

export const AgreementBlock = styled.div`
  box-sizing: border-box;
  padding: 16px 24px;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.primary};

  &.${ADMIN_SETUP_HIGHLIGHTED_AGREEMENT_SECTION_CLASS_NAME} {
    border-color: ${({ theme }) => theme.colors.danger};
    background-color: ${({ theme }) => theme.colors.primaryAlt6};
  }
  transition-property: background-color, border-color;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
`;

export const AgreementBlockTitle = styled(Body2)`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  line-height: 20.58px;

  &.${ADMIN_SETUP_HIGHLIGHTED_AGREEMENT_SECTION_CLASS_NAME} {
    color: ${({ theme }) => theme.colors.danger};
  }
  transition: color 0.2s ease-out;
`;

export const AgreementBlockMessage = styled(Body3)`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.colors.textAlt};
  line-height: 17.64px;
`;

export const AgreementCheckbox = styled(Checkbox)`
  align-items: flex-start;

  ${Checkmark} {
    width: 24px;
    min-width: 24px;
    height: 24px;
  }
`;
