import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import routes from "routes/routes";
import { useAppSelector } from "store/hooks";
import { companyMetadataSelector } from "store/selectors";

import DashboardLayout from "layouts/DashboardLayout";

import { FullScreenLoader } from "uikit";
import { ModalContent, StepIndicator } from "uikit";
import { EButtonsAlign, EButtonsFlex, EModalTypes, IModalProps } from "uikit/Modal";
import { Step } from "uikit/StepIndicator/types";

import WelcomeMessage from "./components/WelcomeMessage";
import { ADMIN_SETUP_STEPS } from "./constants";
import ConnectBank from "./steps/ConnectBank";
import SetupPayroll from "./steps/SetupPayroll";
import { useAdminSetup } from "./useAdminSetup";
import { DefaultFinishedContentWrapper, TabsContainer } from "./styles";

interface IProps {}

const AdminSetupPage: FC<IProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const translationPrefix = `admin_setup_page`;
  const currentCompany = useAppSelector(companyMetadataSelector);
  const { isLoading, currentStep, actions, pageData } = useAdminSetup();
  const {
    moveToOnboardingStep,
    moveToBankAccountsStep,
    handleContinueToBankAccount,
    handleWelcomeComponentContinueClick,
    handleBankAgreementSubmit,
  } = actions;
  const { showWelcomeMessage, isContinueToOnboardingDisabled } = pageData;

  const steps: Step[] = [
    {
      index: ADMIN_SETUP_STEPS.SETUP_PAYROLL,
      label: (
        <Trans
          i18nKey={`${translationPrefix}.steps_template`}
          values={{
            index: ADMIN_SETUP_STEPS.SETUP_PAYROLL,
            name: t(`${translationPrefix}.steps.setup_payroll.title`),
          }}
        />
      ),
      onClick: moveToOnboardingStep,
    },
    {
      index: ADMIN_SETUP_STEPS.CONNECT_BANK,
      label: (
        <Trans
          i18nKey={`${translationPrefix}.steps_template`}
          values={{
            index: ADMIN_SETUP_STEPS.CONNECT_BANK,
            name: t(`${translationPrefix}.steps.connect_bank.title`),
          }}
        />
      ),
      onClick: !isContinueToOnboardingDisabled[ADMIN_SETUP_STEPS.SETUP_PAYROLL]
        ? moveToBankAccountsStep
        : undefined,
    },
  ];

  const renderTabs = () => {
    if (!currentStep) return <></>;
    return (
      <>
        <TabsContainer>
          <StepIndicator steps={steps} currentStep={currentStep} />
        </TabsContainer>
        {currentStep === ADMIN_SETUP_STEPS.SETUP_PAYROLL && (
          <SetupPayroll
            onContinue={handleContinueToBankAccount}
            isContinueDisabled={isContinueToOnboardingDisabled[ADMIN_SETUP_STEPS.SETUP_PAYROLL]}
            onSalsaOnboardingComplete={handleContinueToBankAccount}
          />
        )}
        {currentStep === ADMIN_SETUP_STEPS.CONNECT_BANK && (
          <ConnectBank
            onBack={moveToOnboardingStep}
            onAgreementSubmit={handleBankAgreementSubmit}
          />
        )}
      </>
    );
  };

  const renderDefaultFinishedContent = () => {
    const _translationPrefix = `${translationPrefix}.setup_states.onboarding_complete`;
    const config: Partial<IModalProps> = {
      type: EModalTypes.SUCCESS,
      statusTitle: <Trans i18nKey={`${_translationPrefix}.title`} />,
      message: <Trans i18nKey={`${_translationPrefix}.message`} />,
      mainButton: {
        text: <Trans i18nKey={`${_translationPrefix}.primary_button`} />,
        onClick: () => navigate(routes.PEOPLE),
      },
      secondaryButton: {
        text: <Trans i18nKey={`buttons.do_it_later`} />,
        onClick: () => navigate(routes.ADMIN_PAY),
      },
      buttonsFlex: EButtonsFlex.ROW_REVERSE,
      buttonsAlign: EButtonsAlign.CENTER,
      contentAndButtonsWrapper: DefaultFinishedContentWrapper,
    };
    return <ModalContent {...config} />;
  };

  const renderWelcomeMessage = () => {
    return <WelcomeMessage onContinue={handleWelcomeComponentContinueClick} />;
  };

  const renderContent = () => {
    if (currentCompany?.isOnboardingComplete) {
      return renderDefaultFinishedContent();
    } else if (!!showWelcomeMessage && currentStep === ADMIN_SETUP_STEPS.SETUP_PAYROLL) {
      return renderWelcomeMessage();
    } else {
      return renderTabs();
    }
  };

  return (
    <DashboardLayout showTitle={false} showBreadcrumbs={false}>
      {isLoading && <FullScreenLoader />}
      <>{renderContent()}</>
    </DashboardLayout>
  );
};

export default AdminSetupPage;
