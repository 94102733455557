import { t } from "i18next";
import moment from "moment";

import { CompanyEntityType } from "types/BETypes";
import { defaultDateFormat } from "constants/shared";
import { getAddress, getFormattedPhoneNumber, getHiddenNumber } from "helpers";
import { getCompanyEntityTypeSelectOption } from "helpers/shared/companyType";
import { getStateSelectOption } from "helpers/shared/states";

import { GetBusinessStructureResponseDto, UserResponseDto } from "utils/swagger_react_query";

import { IBusinessInfoRow } from "./types";

export const getBusinessInfoRows = (
  businessOwnersStructure: GetBusinessStructureResponseDto | null,
): IBusinessInfoRow[] => {
  const sharedTranslationPrefix = `components.business_owner_structure.shared`;
  const fieldLabelsTranslationPrefix = `${sharedTranslationPrefix}.inputs_business`;

  const result = [
    [
      {
        label: t(`${fieldLabelsTranslationPrefix}.company_name.label`),
        value: businessOwnersStructure?.business?.companyName,
      },
      {
        label: t(`${fieldLabelsTranslationPrefix}.operating_name.label`),
        value: businessOwnersStructure?.business?.operatingName,
      },
    ],
    [
      {
        label: t(`${fieldLabelsTranslationPrefix}.business_formation_date.label`),
        value: moment(businessOwnersStructure?.business?.dateOfFoundation).format(
          defaultDateFormat,
        ),
      },
      {
        label: t(`${fieldLabelsTranslationPrefix}.state_of_incorporation.label`),
        value: t(
          getStateSelectOption(
            businessOwnersStructure?.business
              ?.formationState as unknown as UserResponseDto["state"],
          ).label,
        ),
      },
    ],
    [
      {
        label: t(`${fieldLabelsTranslationPrefix}.business_structure.label`),
        value: t(
          getCompanyEntityTypeSelectOption(
            businessOwnersStructure?.business?.businessStructure as unknown as CompanyEntityType,
          ).label || "",
        ),
      },
      {
        label: t(`${fieldLabelsTranslationPrefix}.business_phone.label`),
        value: getFormattedPhoneNumber(businessOwnersStructure?.business?.phoneNumber || ""),
      },
    ],
    [
      {
        label: t(`${fieldLabelsTranslationPrefix}.business_email.label`),
        value: businessOwnersStructure?.business?.email,
      },
      {
        label: t(`${fieldLabelsTranslationPrefix}.ein.label_short`),
        value: getHiddenNumber(businessOwnersStructure?.business?.ein || "-", 4),
      },
    ],
    [
      {
        label: t(`${sharedTranslationPrefix}.full_address_label`),
        value: getAddress({
          address: businessOwnersStructure?.business?.address,
          city: businessOwnersStructure?.business?.city,
          state: businessOwnersStructure?.business?.state,
          zip: businessOwnersStructure?.business?.zip,
        }),
        className: "full-width",
      },
    ],
  ];

  return result;
};
